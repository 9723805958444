/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import 'katex/dist/katex.min.css';
import './App0.css';
import React, { useRef, useState } from "react";
import {InlineMath, BlockMath} from 'react-katex';
import { addStyles, EditableMathField, StaticMathField } from "react-mathquill";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {WebService1} from './config';

addStyles();

function obtainValue() {
    return localStorage.getItem("rr1");
}

export default function App0() {
    return (
        <Router>
            <div>
                {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
                <Switch>
                    <Route exact path="/">
                        <ContentsA/>
                    </Route>
                    <Route path="/solvera">
                        <SolverA/>
                    </Route>
                    <Route path="/landing">
                        <Landing0/>
                    </Route>
                    <Route path="/login">
                        <Login00/>
                    </Route>
                    <Route path="/mission">
                        <Test00/>
                    </Route>
                    <Route path="/signup">
                        <SignUp0/>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

// You can think of these components as "pages"
// in your app.


class ContentsA extends React.Component {
    constructor(props) {
        super(props);


        const data = {message: "GET_CONTENT", rr: obtainValue()};

        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);

                this.setState(json, () => {
                    this.arrangeContent();
                    this.goToSelectedExercise(this);
                     // Get the modal
                     if(this.state.lang==="_"){
                     var modal = document.getElementById("change-language-dialog");
                     //Open the modal
                     modal.style.display = "block"   }
                     if(this.state.insertExerciseOpened===true){
                        var modal1 = document.getElementById("ainsert-symbols");
                     //Open the modal
                     modal1.style.display = "block";
                    }else if(this.state.insertExerciseOpened===false){
                        var modal2 = document.getElementById("ainsert-symbols");
                     //Open the modal
                     modal2.style.display = "none";
                    }


                });

               
            })
            .catch(err => console.log(err));

        this.state = {role: "NO"};//initialy

        window.onresize=this.arrangeContent;


    }

    arrangeContent() {

        let totalWidth = window.innerWidth;
       
      

        var a1Elements = document.getElementsByClassName("out");
        var a2Elements = document.getElementsByClassName("my-settings-btn");
        var a3Elements = document.getElementsByClassName("chapters-btn");
        var a4Elements = document.getElementsByClassName("my-language-btn");
        var a5Elements = document.getElementsByClassName("my-students-btn");
        var a6Elements = document.getElementsByClassName("ok0");
        
        var a7Elements = document.getElementsByClassName("list");
        var a8Elements = document.getElementsByClassName("exer");
        var a9Elements = document.getElementsByClassName("solve0");
        var a10Elements = document.getElementsByClassName("decr1");
        var a11Elements = document.getElementsByClassName("icon1");
        var a12Elements=document.getElementsByClassName("chapter0");
        var a13Elements=document.getElementsByClassName("modal-body-list");
        var a14Elements=document.getElementsByClassName("modal-header-list");
        var a15Elements=document.getElementsByClassName("insert-exercise-button");
        var a16Elements=document.getElementsByClassName("insert-exercise-alert");
        var a17Elements=document.getElementsByClassName("math-symbol");
        var a18Elements=document.getElementsByClassName("math-done");
        var a19Elements=document.getElementsByClassName("chapters0");
        var a20Elements=document.getElementsByClassName("k1");
        var a21Elements = document.getElementsByClassName("list2t");
        var a22Elements = document.getElementsByClassName("step2");
        var a23Elements = document.getElementsByClassName("listTask");
        var a24Elements = document.getElementsByClassName("open-class2");
        var a25Elements = document.getElementsByClassName("gen-l");
        var a26Elements = document.getElementsByClassName("desc-t");
        var a27Elements = document.getElementsByClassName("link-t");
        var a28Elements = document.getElementsByClassName("copy-l");
        var a29Elements = document.getElementsByClassName("buttonDel");
        var a30Elements = document.getElementsByClassName("good2");
        var a31Elements = document.getElementsByClassName("close2");
        var a32Elements = document.getElementsByClassName("my-finished-tasks-btn");
        var a33Elements = document.getElementsByClassName("taskbtn");
        var a34Elements = document.getElementsByClassName("send-to-teacher");
        var a35Elements = document.getElementsByClassName("open-class3");
        var a36Elements = document.getElementsByClassName("name0");


       
        
        if (totalWidth <= 1700) {

           

            for (let i = 0; i < a1Elements.length; i++) {
                a1Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a2Elements.length; i++) {
                a2Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a3Elements.length; i++) {
                a3Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a4Elements.length; i++) {
                a4Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a5Elements.length; i++) {
                a5Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a6Elements.length; i++) {
                a6Elements[i].style.fontSize = '52px';
            }
           

            for (let i = 0; i < a7Elements.length; i++) {
                a7Elements[i].style.fontSize = '48px';
                a7Elements[i].style.height = '400px';
            }
            for (let i = 0; i < a8Elements.length; i++) {
                a8Elements[i].style.fontSize = '48px';
            }
            for (let i = 0; i < a9Elements.length; i++) {
                a9Elements[i].style.fontSize = '48px';
            }
            for (let i = 0; i < a10Elements.length; i++) {
                a10Elements[i].style.fontSize = '48px';
            }
            for (let i = 0; i < a11Elements.length; i++) {
                a11Elements[i].style.height = '150px';
            }
            for (let i = 0; i < a12Elements.length; i++) {
                a12Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a13Elements.length; i++) {
                a13Elements[i].style.fontSize = '78px';
            }
            for (let i = 0; i < a14Elements.length; i++) {
                a14Elements[i].style.fontSize = '78px';
            }

            for (let i = 0; i < a15Elements.length; i++) {
                a15Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a16Elements.length; i++) {
                a16Elements[i].style.fontSize = '52px';
            }

            for (let i = 0; i < a17Elements.length; i++) {
                a17Elements[i].style.padding = '30px';
            }

            for (let i = 0; i < a18Elements.length; i++) {
                a18Elements[i].style.padding = '36px';
                a18Elements[i].style.fontSize = '60px';
                a18Elements[i].style.width = '240px';
            }

            for (let i = 0; i < a19Elements.length; i++) {
                a19Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a20Elements.length; i++) {
                a20Elements[i].style.fontSize = '52px';
            }

            for (let i = 0; i < a21Elements.length; i++) {
                a21Elements[i].style.fontSize = '48px';
                a21Elements[i].style.height = '400px';
            }
     
            for (let i = 0; i < a22Elements.length; i++) {
                a22Elements[i].style.fontSize = '48px';
            }

            for (let i = 0; i < a23Elements.length; i++) {
                a23Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a24Elements.length; i++) {
                a24Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a25Elements.length; i++) {
                a25Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a26Elements.length; i++) {
                a26Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a27Elements.length; i++) {
                a27Elements[i].style.fontSize = '20px';
            }
            for (let i = 0; i < a28Elements.length; i++) {
                a28Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a29Elements.length; i++) {
                a29Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a30Elements.length; i++) {
                a30Elements[i].style.fontSize = '40px';
            } 
            for (let i = 0; i < a31Elements.length; i++) {
                a31Elements[i].style.fontSize = '40px';
            }
            for (let i = 0; i < a32Elements.length; i++) {
                a32Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a33Elements.length; i++) {
                a33Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a34Elements.length; i++) {
                a34Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a35Elements.length; i++) {
                a35Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a36Elements.length; i++) {
                a36Elements[i].style.fontSize = '60px';
            }                                              

            
        } else {

          

            for (let i = 0; i < a1Elements.length; i++) {
                a1Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a2Elements.length; i++) {
                a2Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a3Elements.length; i++) {
                a3Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a4Elements.length; i++) {
                a4Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a5Elements.length; i++) {
                a5Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a6Elements.length; i++) {
                a6Elements[i].style.fontSize = '26px';
            }
            


            for (let i = 0; i < a7Elements.length; i++) {
                a7Elements[i].style.fontSize = '14px';
                a7Elements[i].style.height = '80px';
            }
            for (let i = 0; i < a8Elements.length; i++) {
                a8Elements[i].style.fontSize = '14px';
            }
            for (let i = 0; i < a9Elements.length; i++) {
                a9Elements[i].style.fontSize = '14px';
            }

            for (let i = 0; i < a10Elements.length; i++) {
                a10Elements[i].style.fontSize = '14px';
            }
            for (let i = 0; i < a11Elements.length; i++) {
                a11Elements[i].style.height = '25px';
            }

            for (let i = 0; i < a12Elements.length; i++) {
                a12Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a13Elements.length; i++) {
                a13Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a14Elements.length; i++) {
                a14Elements[i].style.fontSize = '26px';
            }


            for (let i = 0; i < a15Elements.length; i++) {
                a15Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a16Elements.length; i++) {
                a16Elements[i].style.fontSize = '26px';
            }

            for (let i = 0; i < a17Elements.length; i++) {
                a17Elements[i].style.padding = '10px';
            }

            for (let i = 0; i < a18Elements.length; i++) {
                a18Elements[i].style.padding = '12px';
                a18Elements[i].style.fontSize = '30px';
                a18Elements[i].style.width = '120px';
            }
            for (let i = 0; i < a19Elements.length; i++) {
                a19Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a20Elements.length; i++) {
                a20Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a21Elements.length; i++) {
                a21Elements[i].style.fontSize = '14px';
                a21Elements[i].style.height = '80px';
            }
            for (let i = 0; i < a22Elements.length; i++) {
                a22Elements[i].style.fontSize = '14px';
            }
            for (let i = 0; i < a23Elements.length; i++) {
                a23Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a24Elements.length; i++) {
                a24Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a25Elements.length; i++) {
                a25Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a26Elements.length; i++) {
                a26Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a27Elements.length; i++) {
                a27Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a28Elements.length; i++) {
                a28Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a29Elements.length; i++) {
                a29Elements[i].style.fontSize = '26px';
            }                
            for (let i = 0; i < a30Elements.length; i++) {
                a30Elements[i].style.fontSize = '20px';
            } 
            for (let i = 0; i < a31Elements.length; i++) {
                a31Elements[i].style.fontSize = '20px';
            }
            for (let i = 0; i < a32Elements.length; i++) {
                a32Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a33Elements.length; i++) {
                a33Elements[i].style.fontSize = '26px';
            } 
            for (let i = 0; i < a34Elements.length; i++) {
                a34Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a35Elements.length; i++) {
                a35Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a36Elements.length; i++) {
                a36Elements[i].style.fontSize = '30px';
            }                                  


        }
        

    }
     
    goToSelectedExercise(state00) {

        let selectedKey = state00.state.selectedProblem;
        if (selectedKey===undefined){selectedKey=""} 
        let el1 = document.getElementById("E" + selectedKey);
        if (el1 !== null) {
            el1.scrollIntoView({block: "nearest", inline: "nearest"});
            console.log("Sel pro:"+selectedKey);
        }
        ;
    }
    navigateTo(problem1) {
        const data = {message: "SET_PROBLEM", problem: problem1, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json);
                window.location = '/solvera';
            })
            .catch(err => console.log(err));
    }


    async copyT(textToCopy) {
        try {
            await navigator.clipboard.writeText(textToCopy);
            console.log('copied to clipboard')
        } catch (error) {
            console.log('failed to copy to clipboard. error=' + error);
        }
    }

    addToTask(problem1) {
        const data = {message: "ADD_TO_TASK", problem: problem1, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });
                

            })
            .catch(err => console.log(err));
    }

         
    sendLatex(latex1) {
        const data = {message: "SEND_LATEX", latex:latex1, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                    this.goToSelectedExercise(this);
                    if(this.state.insertExerciseOpened===true){
                        var modal1 = document.getElementById("ainsert-symbols");
                     //Open the modal
                     modal1.style.display = "block";
                    }else if(this.state.insertExerciseOpened===false){
                        var modal2 = document.getElementById("ainsert-symbols");
                     //Open the modal
                     modal2.style.display = "none";
                    }
                });
                

            })
            .catch(err => console.log(err));
    }
    deleteExerciseFromTask(problem1) {
        const data = {message: "DELETE_EXERCISE_FROM_TASK", problem: problem1, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }


    addAStudent() {
        let isAProblem = false;
        this.setState({"myTeacherAlert": ""});

        this.forceUpdate();
        if (this.state.myTeacher === "") {
            isAProblem = true;
            this.setState({"myTeacherAlert": this.state.stringName.no_teacher_ID_entered});
        }

        if (!isAProblem) {
            const data = {
                message: "ADD_A_STUDENT_TO_TEACHER",
                myTeacher: this.state.myTeacher,
                myTeacherAlert: this.state.myTeacherAlert
                , rr: obtainValue()
            };
            fetch(WebService1, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(json => {
                    //console.log(json);
                    document.getElementById("student00").value = "";
                    this.setState(json,()=>{
                        this.arrangeContent();
                    });

                })
                .catch(err => console.log(err));
        }
    }

    addComment() {
        let isAProblem = false;
        this.setState({myCommentAlert: "", myMarkAlert: ""});
        this.forceUpdate();
        if (this.state.myComment === "") {
            isAProblem = true;
            this.setState({myCommentAlert: this.state.stringName.no_comment_entered});
            this.forceUpdate();
        }
        if (this.state.myMark === "") {
            isAProblem = true;
            this.setState({myMarkAlert: this.state.stringName.no_mark_selected});
            this.forceUpdate();
        }

        if (!isAProblem) {
            const data = {
                message: "ADD_A_COMMENT",
                comment: this.state.myComment,
                mark: this.state.myMark
                , rr: obtainValue()
            };
            fetch(WebService1, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(json => {
                    //console.log(json);

                    this.setState(json,()=>{
                        this.arrangeContent();
                    });

                })
                .catch(err => console.log(err));
        }
    }


    changePSF() {
        let isAProblem = false;
        this.setState({changePSAlert: ""});
        this.forceUpdate();
        if (this.state.old_ps === "") {
            isAProblem = true;
            this.setState({changePSAlert: this.state.stringName.no_old_password_is_entered});
            this.forceUpdate();
        }
       
        if (this.state.ps2.length<8) {
            isAProblem = true;
            this.setState({changePSAlert: this.state.stringName.entered_password_has_less_than_8_chars});
            this.forceUpdate();
        }

        if (this.state.ps1 !== this.state.ps2) {
            isAProblem = true;
            this.setState({changePSAlert: this.state.stringName.entered_passwords_dont_match});
            this.forceUpdate();
        }

        if (!isAProblem) {
            const data = {
                message: "CPS",
                old_ps:this.state.old_ps,
                ps1: this.state.ps1,
                rr: obtainValue()
            };
            fetch(WebService1, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(json => {
                    //console.log(json);

                    this.setState(json,()=>{
                        this.arrangeContent();
                    });

                })
                .catch(err => console.log(err));
        }
    }

    insertData() {
        let isAProblem = false;
        this.setState({myDataAlert: ""});
        this.forceUpdate();
        if (this.state.myData === "") {
            isAProblem = true;
            this.setState({myDataAlert: "No data is inserted"});
            this.forceUpdate();
        } else {
            let dataStructure = JSON.parse(this.state.myData);
            if ((dataStructure !== null) & (dataStructure !== undefined)) {

                if (this.state.myDataType === "chapters") {
                    if (dataStructure.chapters === undefined) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Chapter type:Data has no chapters"});
                        this.forceUpdate();
                    }

                } else if (this.state.myDataType === "en") {

                    if (dataStructure.lang === undefined) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Language type:Data has no lang"});
                        this.forceUpdate();
                    }
                    if (dataStructure.lang !== "en") {
                        isAProblem = true;
                        this.setState({myDataAlert: "Language type:lang is not english"});
                        this.forceUpdate();
                    }
                    let numberOfFields = Object.keys(dataStructure).length;
                    if (numberOfFields < 125) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Language type:number of fields is less than 125"});
                        this.forceUpdate();
                    }

                } else if (this.state.myDataType === "ro") {

                    if (dataStructure.lang === undefined) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Language type:Data has no lang"});
                        this.forceUpdate();
                    }
                    if (dataStructure.lang !== "ro") {
                        isAProblem = true;
                        this.setState({myDataAlert: "Language type:lang is not romanian"});
                        this.forceUpdate();
                    }
                    let numberOfFields = Object.keys(dataStructure).length;
                    if (numberOfFields < 125) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Language type:number of fields is less than 125"});
                        this.forceUpdate();
                    }

                } else if (this.state.myDataType === "formulas") {

                    if (dataStructure.addbothss.f === undefined) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Formulas type:Data has no addbothss.f"});
                        this.forceUpdate();
                    }
                    if (dataStructure.rotequal.f === undefined) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Formulas type:Data has no rotequal.f"});
                        this.forceUpdate();
                    }
                    let numberOfFields = Object.keys(dataStructure).length;
                    if (numberOfFields < 15) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Formulas type:number of fields is less than 15"});
                        this.forceUpdate();
                    }

                } else if (this.state.myDataType === "problem") {

                    if (dataStructure.name === undefined) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Problem type:Data has no name"});
                        this.forceUpdate();
                    }
                    if (dataStructure.type === undefined) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Problem type:Data has no type"});
                        this.forceUpdate();
                    }

                    if (dataStructure.target === undefined) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Problem type:Data has no target"});
                        this.forceUpdate();
                    }
                    if (dataStructure.start === undefined) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Problem type:Data has no start"});
                        this.forceUpdate();
                    }

                    if (dataStructure.formulas === undefined) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Problem type:Data has no formulas"});
                        this.forceUpdate();
                    }

                    if (dataStructure.level === undefined) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Problem type:Data has no level"});
                        this.forceUpdate();
                    }

                    if (dataStructure.chapter === undefined) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Problem type:Data has no chapter"});
                        this.forceUpdate();
                    }

                    if (dataStructure.wave === undefined) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Problem type:Data has no wave"});
                        this.forceUpdate();
                    }

                    let chap0 = dataStructure.chapter;
                    let sub0 = dataStructure.name.substring(0, 3);

                    if (chap0 !== sub0) {
                        isAProblem = true;
                        this.setState({myDataAlert: "Problem type:Chapter is no present in name"});
                        this.forceUpdate();
                    }

                }

            } else {
                isAProblem = true;
                this.setState({myDataAlert: "Insert data cannot be parsed"});
                this.forceUpdate();
            }

        }


        if (!isAProblem) {
            const data = {
                message: "INSERT_DATA",
                data: this.state.myData,
                type: this.state.myDataType
                , rr: obtainValue()
            };
            fetch(WebService1, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(json => {
                    //console.log(json);

                    this.setState(json,()=>{
                        this.arrangeContent();
                    });

                })
                .catch(err => console.log(err));
        }
    }

    taskToDatabase() {
        const data = {message: "TASK_TO_DATABASE", rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    taskToDatabaseLink() {
        const data = {message: "TASK_TO_DATABASE_LINK", rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    taskToTeacherDatabase() {
        const data = {message: "TASK_TO_TEACHER_DATABASE", rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    insertName() {
        const data = {message: "INSERT_NAME",name:this.state.author, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });
                 // Get the modal
                var modal = document.getElementById("sendTaskToTeacher");
                 //Open the modal
                modal.style.display = "block";

            })
            .catch(err => console.log(err));
    }

    addClassToTask(class1) {
        const data = {message: "ADD_CLASS_TO_TASK", class: class1, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    addAStudentToAClass(student1) {
        const data = {
            message: "ADD_A_STUDENT_TO_A_CLASS",
            student: student1,
            class: this.state.selectedClass
            , rr: obtainValue()
        };
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    deleteAStudent() {
        const data = {
            message: "DELETE_A_STUDENT",
            student: this.state.markedStudentToBeDeleted
            , rr: obtainValue()
        };
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    clearAllSolutions() {
        const data = {
            message: "CLEAR_ALL_SOLUTIONS", rr: obtainValue()
        };
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    deleteProblemAndSolution(problem0) {
        const data = {
            message: "DELETE_PROBLEM_AND_SOLUTION", problem: problem0, rr: obtainValue()
        };
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    deleteClassToTask() {
        const data = {message: "DELETE_CLASS_TO_TASK", rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    getMyTasks() {
        const data = {message: "GET_MY_TASKS", rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    getPending() {
        const data = {message: "GET_PENDING", rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    production() {
        const data = {message: "INSERT_INTO_PRODUCTION", rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    getMyFinishedTasks() {
        const data = {message: "GET_MY_FINISHED_TASKS", date: this.state.dateOfTask, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    getMyFinishedTasksByKey() {
        const data = {message: "GET_MY_FINISHED_TASKS_BY_KEY", task: this.state.keyOfTask, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    getMyFinishedTasksByLastKey() {
        const data = {message: "GET_MY_FINISHED_TASKS_BY_LAST_KEY", rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    getMyClasses() {
        const data = {message: "GET_MY_CLASSES", rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }


    getDetails(page0) {
        const data = {message: "GET_DETAILS", rr: obtainValue(), page: page0};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    getChapters() {
        const data = {message: "GET_CHAPTERS", rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);
                
                this.setState(json,()=>{
                    this.arrangeContent();
                });
                

            })
            .catch(err => console.log(err));
    }

    getChapterContent(code1, name1) {
        const data = {message: "GET_CHAPTER_CONTENT", code: code1, name: name1, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);
                
                this.setState(json,()=>{this.arrangeContent();});
                

            })
            .catch(err => console.log(err));
    }


    getClassLess() {
        const data = {message: "GET_CLASS_LESS", rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    openTask(teacher1, task1) {
        const data = {message: "OPEN_TASK", teacher: teacher1, task: task1, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    openPending(problem0) {
        const data = {message: "OPEN_PENDING", problem: problem0, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    deleteATask() {
        const data = {
            message: "DELETE_A_TASK",
            teacher: this.state.markedTaskToBeDeleted.teacher,
            task: this.state.markedTaskToBeDeleted.task
            , rr: obtainValue()
        };
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }


    openFinishedTask(student1, task1) {
        const data = {message: "OPEN_FINISHED_TASK", student: student1, task: task1, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));
    }

    out() {
        const data = {message: "OUT", rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });
                localStorage.clear();
                window.location = '/';
            })
            .catch(err => console.log(err));
    }

    dag0() {
        const data = {message: "DAG0", rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                 this.arrangeContent();    
            })
            .catch(err => console.log(err));
    }

    printClasses() {
        let print0 = "";
        this.state.sentToClasses.map((value, index) => {
            print0 = print0 + " " + value
        });
        return print0;
    }

    printClassesToSend() {
        let print0 = "";
        this.state.sentToClasses.map((value1, index) => {
                let description0 = "";
                this.state.myClasses.map((value2) => {
                    if (value2.key === value1) {
                        description0 = value2.description;
                    }
                })
                print0 = print0 + " " + value1 + ":" + description0 + ";    "
            }
        );
        return print0;
    }

    printExerciseToSend() {
        let solvedExercises = 0;
        let totalExercises = this.state.exercises.length;
        this.state.exercises.map((value) => {
                if (value.solved === true) {
                    solvedExercises++;
                }

            }
        );

        return this.state.stringName.solved2 + solvedExercises + this.state.stringName.of_the_total + totalExercises;
    }


    checkTaskErrors() {
        let errors0 = "";
        if (this.state.task.exercises.length === 0) {
            errors0 = errors0 + this.state.stringName.exercises_must_be_added_to_the_mission;
        }
        if (this.state.sentToClasses.length === 0) {
            errors0 = errors0 + this.state.stringName.classrooms_must_be_selected_for_the_mission;
        }

        if (errors0 !== "") {
            this.setState({"taskErrors": errors0});
            this.forceUpdate();
            this.showTaskError();
            this.forceUpdate();
        } else {
            this.showSendTask();
            this.forceUpdate();
        }

    }

    checkTaskErrorsOnLink() {
        let errors0 = "";
        if (this.state.task.exercises.length === 0) {
            errors0 = errors0 + this.state.stringName.exercises_must_be_added_to_the_mission;
        }
      

        if (errors0 !== "") {
            this.setState({"taskErrors": errors0});
            this.forceUpdate();
            this.showTaskError();
            this.forceUpdate();
        } else {
            this.showGenerateTask();
            this.forceUpdate();
        }

    }

    showTaskError() {
        // Get the modal
        var modal = document.getElementById("TaskError");
        //Open the modal
        modal.style.display = "block"

    }

    closeTaskError() {

        var modal = document.getElementById("TaskError");
        modal.style.display = "none";


    }

    showSendTask() {
        // Get the modal
        var modal = document.getElementById("sendTask");
        //Open the modal
        modal.style.display = "block"

    }

    showGenerateTask() {
        // Get the modal
        var modal = document.getElementById("generateTask");
        //Open the modal
        modal.style.display = "block"

    }

    showSendTaskToTeacher() {

        if(this.state.author!==""){
        // Get the modal
        var modal = document.getElementById("sendTaskToTeacher");
        //Open the modal
        modal.style.display = "block";
        }else{
             // Get the modal
        var modal2 = document.getElementById("insertName");
        //Open the modal
        modal2.style.display = "block";

        }

    }

    showInsertExercise() {
        // Get the modal
        var modal1 = document.getElementById("ainsert-symbols");
        //Open the modal
        modal1.style.display = "block"

    }

    showAddAStudent() {
        // Get the modal
        var modal = document.getElementById("addAStudent");
        //Open the modal
        modal.style.display = "block"

    }

    showMyTasks() {

        this.getMyTasks();
        this.forceUpdate();

    }

    showPending() {

        this.getPending();
        this.forceUpdate();

    }

    showMyFinishedTasks() {

        this.getMyFinishedTasks();
        this.forceUpdate();

    }

    showMyFinishedTasksByKey() {

        this.getMyFinishedTasksByKey();
        this.forceUpdate();

    }       
    showMyFinishedTasksByLastKey() {

        this.getMyFinishedTasksByLastKey();
        this.forceUpdate();

    } 
    showMyClasses() {

        this.getMyClasses();
        this.forceUpdate();

    }

    showDetails() {

        this.getDetails("1");
        this.forceUpdate();

    }

    upPageDetails() {

        this.getDetails("++");
        this.forceUpdate();

    }

    downPageDetails() {

        this.getDetails("--");
        this.forceUpdate();

    }

    showChapters() {

        this.getChapters();
        this.forceUpdate();

    }

    showClassLess() {

        this.getClassLess();
        this.forceUpdate();

    }


    closeSendTask() {

        var modal = document.getElementById("sendTask");
        modal.style.display = "none";


    }
    closeGenerateTask() {

        var modal = document.getElementById("generateTask");
        modal.style.display = "none";


    }

    closeSendTaskToTeacher() {

        var modal = document.getElementById("sendTaskToTeacher");
        modal.style.display = "none";


    }

    closeInsertName() {

        var modal = document.getElementById("insertName");
        modal.style.display = "none";


    }

    closeAddAStudent() {

        var modal = document.getElementById("addAStudent");
        modal.style.display = "none";


    }

    addANewClass() {

        let description00 = document.getElementById("my-new-class").value;


        const data = {message: "ADD_A_NEW_CLASS", description: description00, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));

    }


    modifyClass(key1) {

        let description00 = document.getElementById("class_" + key1).value;


        const data = {message: "MODIFY_CLASS", key: key1, description: description00, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));

    }


    changeLanguage(lang1) {


        const data = {message: "CHANGE_LANGUAGE", lang: lang1, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));

    }


    takeOutStudent(key1, student1) {


        const data = {message: "TAKE_OUT_STUDENT", key: key1, student: student1, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    this.arrangeContent();
                });

            })
            .catch(err => console.log(err));

    }

    getUserName(id0) {
        let name0 = "";
        if (this.state.namesOfUsers === undefined) {
            return name0;
        } else {
            this.state.namesOfUsers.map((value) => {
                if (value.id === id0) {
                    name0 = value.name;
                }
            })
        }
        return name0;

    }

    setMyComment(event1) {
        this.setState({"myComment": event1.target.value});
    }

    setMyMark(event1) {
        this.setState({"myMark": event1.target.value});
    }

    setOPS(event1) {
        this.setState({"old_ps": event1.target.value});
    }

    setPS1(event1) {
        this.setState({"ps1": event1.target.value});
    }

    setPS2(event1) {
        this.setState({"ps2": event1.target.value});
    }

    fandl(s){
       let s2="";
       let l=s.length-1;
       if(s.length>1){
         s2=s.charAt(0)+"..."+s.charAt(l); 
       }else{
        s2=s.charAt(0)+"...";
       }
       return s2; 
    } 
    render() {
        if (this.state.l0 === false) {
            return (<Redirect to="/landing"/>);
        } else if (this.state.role === "1") {
            return (
                <div className="ContentsA">

                    <ListChapters
                        getChapterContent={this.getChapterContent.bind(this)}
                        showChapters={this.showChapters.bind(this)}
                        forceUpdate0={this.forceUpdate.bind(this)}
                        chapters={this.state.chapters}
                        stringName={this.state.stringName}
                    />             
                   
                   <ListChangeLanguage
                      changeLanguage={this.changeLanguage.bind(this)} 
                    />
                    
                    <InsertExercise
                                  
                
                    
                    sendLatex={this.sendLatex.bind(this)}
                    formulaKatex={this.state.formulaKatex}
                    
                    insertAlert={this.state.insertAlert}
                    stringName={this.state.stringName}
                    
                                      
                    />

                    <button className="chapters-btn" onClick={() => {
                       this.showChapters();
                     var modal = document.getElementById("chapter-id");
                     modal.style.display = "block";
                     }}>{this.state.stringName.collection}</button> 
                    
                    <div id="delete-task-dialog" class="modal">

                        <div class="modal-content">

                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <p>{this.state.stringName.you_want_to_delete_the_assigned_mission}<br></br>
                                    {this.state.stringName.teacher + this.state.markedTaskToBeDeleted.teacher + " " +
                                        this.state.stringName.mission3 + this.state.markedTaskToBeDeleted.task}
                                </p>
                                <button class="good" onClick={() => {
                                    this.deleteATask();
                                    var modal1 = document.getElementById("delete-task-dialog");
                                    modal1.style.display = "none";
                                }}>{this.state.stringName.yes_delete} </button>
                                <button class="close" onClick={() => {
                                    var modal1 = document.getElementById("delete-task-dialog");
                                    modal1.style.display = "none";
                                }}>{this.state.stringName.not_stop_it} </button>
                            </div>

                            <div class="modal-footer">
                            </div>

                        </div>

                    </div>

                   

                    <div id="my-tasks-btn-open" class="modal-list">

                        <div class="modal-content-list">

                            <div class="modal-header-list">

                            </div>

                            <div class="modal-body-list">
                                {(this.state.myTasks.length === 0 ? this.state.stringName.missions_from_teachers_not_exists : '')}
                                {this.state.myTasks.map(t_i =>
                                    <div
                                        className="listTask1">{this.state.stringName.teacher + t_i.teacher + this.state.stringName.mission2 + t_i.task}
                                        <button className="delete-class" onClick={() => {
                                            this.setState({
                                                markedTaskToBeDeleted: {
                                                    teacher: t_i.teacher,
                                                    task: t_i.task
                                                }
                                            });
                                            var modal1 = document.getElementById("delete-task-dialog");
                                            modal1.style.display = "block";
                                            var modal2 = document.getElementById("my-tasks-btn-open");
                                            modal2.style.display = "none";
                                        }}
                                        >{this.state.stringName.delete}
                                        </button>
                                        <button className="open-class" onClick={() => {
                                            this.openTask(t_i.teacher, t_i.task);
                                            var modal_list = document.getElementById("my-tasks-btn-open");
                                            modal_list.style.display = "none";
                                            this.forceUpdate();
                                        }}>{this.state.stringName.open_this_mission}</button>
                                    </div>
                                )}

                            </div>

                            <div class="modal-footer-list">
                                <button className="ok0" onClick={() => {
                                    var modal_list = document.getElementById("my-tasks-btn-open");
                                    modal_list.style.display = "none";
                                }}>{this.state.stringName.ok}</button>
                            </div>

                        </div>

                    </div>


                    <div id="insertName" class="modal">

                        <div class="modal-content">

                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <p className='open-class2'>{this.state.stringName.please_insert_your_full_name}
                                </p>
                                <input className="name0"
                                       id="author01"
                                       type='text'
                                       name='author'
                                       onChange={(event) => {
                                           this.setState({"author": event.target.value});
                                       }}
                                />
                                <button className="good2" onClick={() => {this.closeInsertName();this.insertName();                                                               
                                }}>{this.state.stringName.insert_full_name} </button>
                               
                            </div>

                            <div class="modal-footer">
                            </div>

                        </div>

                    </div>


                    <div id="sendTaskToTeacher" class="modal">

                        <div class="modal-content">

                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <p className='open-class2'>{this.state.stringName.do_you_really_want_to_send_the_solved_mission_to_teacher}<br></br>{this.printExerciseToSend()}
                                </p>
                                <button className="good2" onClick={() => {
                                    this.closeSendTaskToTeacher();
                                    this.taskToTeacherDatabase();
                                }}>{this.state.stringName.yes_send_the_mission} </button>
                                <button className="close2"
                                        onClick={() => this.closeSendTaskToTeacher()}>{this.state.stringName.not_stop_it} </button>
                            </div>

                            <div class="modal-footer">
                            </div>

                        </div>

                    </div>


                    <div id="addAStudent" class="modal">

                        <div class="modal-content">

                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <button className="user_pass"
                                        onClick={() => this.addAStudent()}> {this.state.stringName.add_me_to_the_teacher}</button>
                                <input className="user_pass"
                                       id="student00"
                                       type='text'
                                       name='myTeacher'
                                       onChange={(event) => {
                                           this.setState({"myTeacher": event.target.value});
                                       }}
                                />
                                <p className="signupAlert"> {this.state.myTeacherAlert}</p>
                                <button class="close"
                                        onClick={() => this.closeAddAStudent()}>{this.state.stringName.close_the_dialog} </button>
                            </div>

                            <div class="modal-footer">
                            </div>

                        </div>

                    </div>


                    <ListComment
                        myMark={this.state.myMark}
                        myMarkAlert={this.state.myMarkAlert}
                        setMyMark={this.setMyMark.bind(this)}

                        myCommentAlert={this.state.myCommentAlert}
                        setMyComment={this.setMyComment.bind(this)}

                        forceUpdate0={this.forceUpdate.bind(this)}
                        addComment={this.addComment.bind(this)}
                        stringName={this.state.stringName}
                    />


                     <ChangePS
                         setOPS={this.setOPS.bind(this)}
                         setPS1={this.setPS1.bind(this)}
                         setPS2={this.setPS2.bind(this)}
                         changePSAlert={this.state.changePSAlert}
                         forceUpdate0={this.forceUpdate.bind(this)}
                         changePSF={this.changePSF.bind(this)}
                         stringName={this.state.stringName}
                    />


                    <div id="clear-all-solutions-dialog" class="modal">

                        <div class="modal-content">

                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <p className='open-class2'>{this.state.stringName.do_you_want_to_delete_all_solutions_of_the_current_user}

                                </p>
                                <button className="good2" onClick={() => {
                                    this.clearAllSolutions();
                                    var modal1 = document.getElementById("clear-all-solutions-dialog");
                                    modal1.style.display = "none";
                                }}>{this.state.stringName.yes_delete} </button>
                                <button className="close2" onClick={() => {
                                    var modal1 = document.getElementById("clear-all-solutions-dialog");
                                    modal1.style.display = "none";
                                }}>{this.state.stringName.not_stop_it} </button>
                            </div>

                            <div class="modal-footer">
                            </div>

                        </div>

                    </div>


                    <button className="my-settings-btn" onClick={() => {
                        var modal_list = document.getElementById("my-settings-btn-open");
                        modal_list.style.display = "block";
                    }}>{this.state.stringName.settings}</button>
                    <div id="my-settings-btn-open" class="modal-list">

                        <div class="modal-content-list">

                            <div class="modal-header-list">
                                {this.state.stringName.settings}
                            </div>

                            <div class="modal-body-list">

                                <label className="my-language-btn"> Change the language:
                                    <form>
                                        <select
                                            onChange={(event) => {
                                                this.changeLanguage(event.target.value);
                                            }}
                                            value={this.state.lang}
                                            className="my-language-btn"

                                        >
                                            <option value="en">english</option>
                                            <option value="ro"> română</option>


                                        </select>
                                    </form>
                                </label>

                                <button className="my-students-btn" onClick={() => {
                                    var modal = document.getElementById("clear-all-solutions-dialog");
                                    modal.style.display = "block";
                                    var modal_list2 = document.getElementById("my-settings-btn-open");
                                    modal_list2.style.display = "none";
                                }}> {this.state.stringName.clear_all_solutions} </button>
                                <br></br>                             

                                <button className="my-students-btn" onClick={() => {
                                    var modal = document.getElementById("rateApp");
                                    modal.style.display = "block";
                                    var modal_list2 = document.getElementById("my-settings-btn-open");
                                    modal_list2.style.display = "none";
                                }}> {this.state.stringName.add_a_review_to_the_application} </button>

                                <button className="my-students-btn" onClick={() => {
                                    var modal = document.getElementById("changeps");
                                    modal.style.display = "block";
                                    var modal_list2 = document.getElementById("my-settings-btn-open");
                                    modal_list2.style.display = "none";
                                }}> {this.state.stringName.change_the_password} </button>
                            </div>

                            <div class="modal-footer-list">
                                <button className="ok0" onClick={() => {
                                    var modal_list = document.getElementById("my-settings-btn-open");
                                    modal_list.style.display = "none";
                                }}>{this.state.stringName.ok}</button>
                            </div>

                        </div>

                    </div>


                    <button className="out"
                            onClick={() => this.out()}> {this.state.stringName.logout + ":" + this.fandl(this.state.username)}  </button>

                    <p className="chapter0"> {this.state.chapterName} </p>

                    <header className="ContentExercises">

                        <ListExercisesA role={this.state.role} type={this.state.type} exercises={this.state.exercises}
                                        navigateTo={this.navigateTo.bind(this)}
                                        addToTask={this.addToTask.bind(this)}
                                        showSendTaskToTeacher={this.showSendTaskToTeacher.bind(this)}
                                        stringName={this.state.stringName}
                                        author={this.state.author}
                        />

                    </header>
                   
                  
                
                </div>
            );
        } else if (this.state.role === "2") {
            return (
                <div className="ContentsA">

                    <ListChapters
                        getChapterContent={this.getChapterContent.bind(this)}
                        showChapters={this.showChapters.bind(this)}
                        forceUpdate0={this.forceUpdate.bind(this)}
                        chapters={this.state.chapters}
                        stringName={this.state.stringName}
                    />
                    
                    <ListChangeLanguage
                      changeLanguage={this.changeLanguage.bind(this)} 
                    />

                    <InsertExercise
                                          
                    
                    
                    sendLatex={this.sendLatex.bind(this)}
                    formulaKatex={this.state.formulaKatex}
                    
                    insertAlert={this.state.insertAlert}
                    stringName={this.state.stringName}
                    
                                      
                    />

                      <div id="insertName" class="modal">

                      <div class="modal-content">

                      <div class="modal-header">

                      </div>

                      <div class="modal-body">
                      <p className='open-class2'>{this.state.stringName.please_insert_your_full_name}
                      </p>
                      <input className="name0"
                             id="author01"
                             type='text'
                             name='author'
                             onChange={(event) => {
                                      this.setState({"author": event.target.value});
                                      }}
                       />
                      <button className="good2" onClick={() => {this.closeInsertName();this.insertName();                                                               
                        }}>{this.state.stringName.insert_full_name} </button>
       
                     </div>

                     <div class="modal-footer">
                     </div>

                     </div>

                   </div>
                   <div id="sendTaskToTeacher" class="modal">

                   <div class="modal-content">

                   <div class="modal-header">

                   </div>

                   <div class="modal-body">
                   <p className='open-class2'>{this.state.stringName.do_you_really_want_to_send_the_solved_mission_to_teacher}<br></br>{this.printExerciseToSend()}
                   </p>
                   <button className="good2" onClick={() => {
                    this.closeSendTaskToTeacher();
                    this.taskToTeacherDatabase();
                    }}>{this.state.stringName.yes_send_the_mission} </button>
                   <button className="close2"
                   onClick={() => this.closeSendTaskToTeacher()}>{this.state.stringName.not_stop_it} </button>
                   </div>

                   <div class="modal-footer">
                   </div>

                   </div>

                   </div>


                   <button className="chapters-btn" onClick={() => {
                       this.showChapters();
                     var modal = document.getElementById("chapter-id");
                     modal.style.display = "block";
                     }}>{this.state.stringName.collection}</button> 

                    <button className="taskbtn" onClick={() => {
                        this.showMyClasses();
                        var modal_list = document.getElementById("taskbtn-open");
                        modal_list.style.display = "block";
                    }
                    }>{this.state.stringName.mission + this.state.task.exercises.length + this.state.stringName.exercises}</button>


                    <div id="taskbtn-open" class="modal-list">

                        <div class="modal-content-list">

                            <div class="modal-header-list">
                                {this.state.stringName.mission_content}
                            </div>

                            <div className="modal-body-list">
                                {(this.state.task.exercises.length === 0 ? this.state.stringName.no_exercises_added : '')}
                                <header>

                                    <ListExercisesT exercises={this.state.task.exercises}
                                                    deleteExerciseFromTask={this.deleteExerciseFromTask.bind(this)}
                                                    stringName={this.state.stringName}/>

                                </header>                             

                                 {(this.state.task.exercises.length>0)?<button className="gen-l" onClick={() => {
                                    this.checkTaskErrorsOnLink();
                                  }}>{this.state.stringName.generate_the_mission_link}
                                 </button>:""}
                                

                                 <label className="desc-t">{this.state.taskDescription}
                                 </label>
                                 <label className="link-t">{this.state.stringName.mission_link+" : "+this.state.taskLink}
                                 </label> 
                                 {(this.state.taskLink!=="")?<button className="copy-l" onClick={()=>{this.copyT(this.state.taskLink)}}>{this.state.stringName.copy_the_link}</button>:""}   

                            </div>

                            <div class="modal-footer-list">
                                <button className="ok0" onClick={() => {
                                    var modal_list = document.getElementById("taskbtn-open");
                                    modal_list.style.display = "none";
                                }}> {this.state.stringName.ok} </button>
                            </div>

                        </div>

                    </div>


                    <div id="TaskError" class="modal">

                        <div class="modal-contentTE">

                            <div class="modal-headerTE">

                            </div>

                            <div class="modal-bodyTE">
                                <p>{this.state.stringName.there_are_errors_before_sending_mission_to_students}<br></br>{this.state.taskErrors}
                                </p>
                                <button class="close"
                                        onClick={() => this.closeTaskError()}>{this.state.stringName.ok}</button>
                            </div>

                            <div class="modal-footerTE">
                            </div>

                        </div>

                    </div>


                    <div id="sendTask" class="modal">

                        <div class="modal-content">

                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <p>{this.state.stringName.do_you_really_want_to_submit_the_mission_to_the_following_classrooms}<br></br>
                                    {this.printClassesToSend()} </p>
                                <button class="good" onClick={() => {
                                    this.closeSendTask();
                                    this.taskToDatabase();
                                    var modal_list = document.getElementById("taskbtn-open");
                                    modal_list.style.display = "none";
                                }}>{this.state.stringName.yes_send_the_mission} </button>
                                <button class="close"
                                        onClick={() => this.closeSendTask()}>{this.state.stringName.not_stop_it} </button>
                            </div>

                            <div class="modal-footer">
                            </div>

                        </div>

                    </div>

                    <div id="generateTask" class="modal">

                     
                     <div class="modal-content">

                     <div class="modal-header">

                     </div>

                     <div class="modal-body">
                     <p className='open-class2'>{this.state.stringName.do_you_really_want_to_generate_the_mission_link}<br></br>
                     </p>
                     <button className="good2" onClick={() => {
                     this.closeGenerateTask();
                     this.taskToDatabaseLink();
                     
                     }}>{this.state.stringName.yes_generate_the_mission_link} </button>
                    <button className="close2"
                     onClick={() => this.closeGenerateTask()}>{this.state.stringName.not_stop_it} </button>
                    </div>

                    <div class="modal-footer">
                    </div>

                  </div>

              </div>


                    <button className="my-finished-tasks-btn" onClick={() => {
                        this.showMyFinishedTasksByLastKey();
                        var modal_list = document.getElementById("my-finished-tasks-btn-open");
                        modal_list.style.display = "block";
                    }}>{this.state.stringName.solutions_to_missions}</button>

                    <div id="my-finished-tasks-btn-open" class="modal-list">

                        <div class="modal-content-list">

                            <div class="modal-header-list">
                              

                                <label> {this.state.stringName.insert_mission_key}
                                    <input
                                        className="open-class2"
                                        type="number"
                                        name='keyOfTask'
                                        value={this.state.keyOfTask}
                                        onChange={(event) => {
                                            this.setState({"keyOfTask": event.target.value});
                                        }}
                                    />
                                </label>
                                <button  className="open-class2" onClick={() => {
                                    this.showMyFinishedTasksByKey();
                                }}>{this.state.stringName.search_by_key}</button>
                                <br></br>
                                {this.state.stringName.solutions_to_missions}
                            </div>

                            <div class="modal-body-list">
                                {(this.state.myFinishedTasks.length === 0 ? this.state.stringName.there_are_no_missions_solved : '')}
                                {this.state.myFinishedTasks.map(t_i =>
                                    <div className="listTask">{this.state.stringName.student+t_i.author +
                                        this.state.stringName.mission2 + t_i.task +
                                        this.state.stringName.solved3 + t_i.solvedExercises + "/" + t_i.totalExercises+" in "+t_i.minutesString+"min."}
                                        <button className="open-class3"
                                                onClick={() => {
                                                    this.openFinishedTask(t_i.student, t_i.task);
                                                    this.forceUpdate();
                                                    var modal_list = document.getElementById("my-finished-tasks-btn-open");
                                                    modal_list.style.display = "none";
                                                }}>{this.state.stringName.open_the_solution_of_mission}</button>
                                    </div>
                                )}

                            </div>

                            <div class="modal-footer-list">
                                <button className="ok0" onClick={() => {
                                    var modal_list = document.getElementById("my-finished-tasks-btn-open");
                                    modal_list.style.display = "none";
                                }}>{this.state.stringName.ok}</button>
                            </div>

                        </div>

                    </div>


                    <div id="my-classes-btn-open" class="modal-list">

                        <div class="modal-content-list">

                            <div class="modal-header-list">
                                {this.state.stringName.my_classrooms}
                            </div>


                            <div class="modal-body-list">
                                <div className="listTask">
                                    <button className="add-class"
                                            onClick={() => this.addANewClass()}> {this.state.stringName.add_a_new_classroom} </button>
                                    <input className="input-box" id="my-new-class"
                                           type='text'
                                           name='my-new-class'
                                    />
                                </div>
                                {(this.state.myClasses.length === 0 ? this.state.stringName.there_are_no_classrooms : '')}
                                {this.state.myClasses.map(class_i =>
                                    <div
                                        className="listClass">{this.state.stringName.classroom + class_i.key + " : " + class_i.description}
                                        <input className="input-box" id={"class_" + class_i.key} type='text'/>
                                        <button className="modify-class" onClick={() => this.modifyClass(class_i.key)}>
                                            {this.state.stringName.change_classroom_name} </button>
                                        <div className="listStudents">
                                            <br></br> {this.state.stringName.students} <br></br>
                                            {(class_i.students.length === 0 ? this.state.stringName.there_are_no_students_they_must_be_added : '')}
                                            {class_i.students.map((value0, index) =>
                                                <div
                                                    className="listAStudent">{(index + 1) + ":" + value0 + ":" + this.getUserName(value0)}
                                                    <button className="modify-class"
                                                            onClick={() => this.takeOutStudent(class_i.key, value0)}>
                                                        {this.state.stringName.take_out_student} </button>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                )}

                            </div>


                            <div class="modal-footer-list">
                                <button className="ok0" onClick={() => {
                                    var modal_list = document.getElementById("my-classes-btn-open");
                                    modal_list.style.display = "none";
                                }}>{this.state.stringName.ok}</button>
                            </div>

                        </div>

                    </div>


                    <div id="delete-student-dialog" class="modal">

                        <div class="modal-content">

                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <p>{this.state.stringName.you_want_to_delete_the_student_without_a_classroom}<br></br>
                                    {this.state.stringName.student + this.state.markedStudentToBeDeleted + "::" + this.getUserName(this.state.markedStudentToBeDeleted)}
                                </p>
                                <button class="good" onClick={() => {
                                    this.deleteAStudent();
                                    var modal1 = document.getElementById("delete-student-dialog");
                                    modal1.style.display = "none";
                                }}>{this.state.stringName.yes_delete} </button>
                                <button class="close" onClick={() => {
                                    var modal1 = document.getElementById("delete-student-dialog");
                                    modal1.style.display = "none";
                                }}>{this.state.stringName.not_stop_it} </button>
                            </div>

                            <div class="modal-footer">
                            </div>

                        </div>

                    </div>


                    <div id="my-students-btn-open" class="modal-list">

                        <div class="modal-content-list">

                            <div class="modal-header-list">
                                {this.state.stringName.students_without_classroom}
                            </div>

                            <div class="modal-body-list">

                                {(this.state.classLess.length === 0 ? this.state.stringName.there_are_no_students_without_classroom : '')}
                                {this.state.classLess.map(student_i =>
                                    <div
                                        className="listAStudent">{this.state.stringName.student + student_i + this.state.stringName.name_surname + this.getUserName(student_i)}

                                        <label className="add-class">  {this.state.stringName.select_classroom}
                                            <select name={"selectt2" + student_i}
                                                    onChange={(event) => {
                                                        this.setState({"selectedClass": event.target.value});
                                                    }}
                                                    value={this.state.selectedClass}
                                                    className="select-class"
                                            >
                                                {this.state.myClasses.map(class_j =>
                                                    <option key={class_j.key}
                                                            value={class_j.key}>{class_j.key + ":" + class_j.description}</option>
                                                )};
                                            </select>
                                        </label>

                                        <button className="add-class"
                                                onClick={() => this.addAStudentToAClass(student_i)}>
                                            {this.state.stringName.add_the_student_to_selected_classroom}</button>

                                        <button className="delete-class" onClick={() => {
                                            this.setState({markedStudentToBeDeleted: student_i});
                                            var modal1 = document.getElementById("delete-student-dialog");
                                            modal1.style.display = "block";
                                            var modal2 = document.getElementById("my-students-btn-open");
                                            modal2.style.display = "none";
                                        }}>{this.state.stringName.delete}
                                        </button>

                                    </div>
                                )}

                            </div>

                            <div class="modal-footer-list">
                                <button className="ok0" onClick={() => {
                                    var modal_list = document.getElementById("my-students-btn-open");
                                    modal_list.style.display = "none";
                                }}> {this.state.stringName.ok}</button>
                            </div>

                        </div>

                    </div>


                    <ListComment
                        myMark={this.state.myMark}
                        myMarkAlert={this.state.myMarkAlert}
                        setMyMark={this.setMyMark.bind(this)}

                        myCommentAlert={this.state.myCommentAlert}
                        setMyComment={this.setMyComment.bind(this)}

                        forceUpdate0={this.forceUpdate.bind(this)}
                        addComment={this.addComment.bind(this)}
                        stringName={this.state.stringName}
                    />

                     <ChangePS 
                         setOPS={this.setOPS.bind(this)}
                         setPS1={this.setPS1.bind(this)}
                         setPS2={this.setPS2.bind(this)}
                         changePSAlert={this.state.changePSAlert}
                         forceUpdate0={this.forceUpdate.bind(this)}
                         changePSF={this.changePSF.bind(this)}
                         stringName={this.state.stringName}
                    />


                    <button className="my-settings-btn" onClick={() => {
                        var modal_list = document.getElementById("my-settings-btn-open");
                        modal_list.style.display = "block";
                    }}>{this.state.stringName.settings}</button>
                    <div id="my-settings-btn-open" class="modal-list">

                        <div class="modal-content-list">

                            <div class="modal-header-list">
                                {this.state.stringName.settings + ":"}
                            </div>

                            <div class="modal-body-list">


                                <label className="my-language-btn"> Change the language:
                                    <form>
                                        <select
                                            onChange={(event) => {
                                                this.changeLanguage(event.target.value);
                                            }}
                                            value={this.state.lang}
                                            className="my-language-btn"

                                        >
                                            <option value="en">english</option>
                                            <option value="ro"> română</option>


                                        </select>
                                    </form>
                                </label>
                              
                                <br></br>
                                                              
                                <button className="my-students-btn" onClick={() => {
                                    var modal = document.getElementById("rateApp");
                                    modal.style.display = "block";
                                    var modal_list2 = document.getElementById("my-settings-btn-open");
                                    modal_list2.style.display = "none";
                                }}> {this.state.stringName.add_a_review_to_the_application} </button>
                                 
                                 <button className="my-students-btn" onClick={() => {
                                    var modal = document.getElementById("changeps");
                                    modal.style.display = "block";
                                    var modal_list2 = document.getElementById("my-settings-btn-open");
                                    modal_list2.style.display = "none";
                                }}> {this.state.stringName.change_the_password} </button>

                            </div>

                            <div class="modal-footer-list">
                                <button className="ok0" onClick={() => {
                                    var modal_list = document.getElementById("my-settings-btn-open");
                                    modal_list.style.display = "none";
                                }}>{this.state.stringName.ok}</button>
                            </div>

                        </div>

                    </div>


                    <button className="out"
                            onClick={() => this.out()}> {this.state.stringName.logout + ":" + this.fandl(this.state.username)}  </button>

                    <p className="chapter0"> {this.state.chapterName} </p>

                    <header className="ContentExercises">

                        <ListExercisesA role={this.state.role} type={this.state.type} exercises={this.state.exercises}
                                        navigateTo={this.navigateTo.bind(this)}
                                        addToTask={this.addToTask.bind(this)}
                                        showSendTaskToTeacher={this.showSendTaskToTeacher.bind(this)}
                                        stringName={this.state.stringName}
                                        author={this.state.author}
                        />

                    </header>
                   

                </div>
            );
        } else if (this.state.role === "3") {
            return (
                <div className="ContentsA">
                        
                    <ListChapters
                        getChapterContent={this.getChapterContent.bind(this)}
                        showChapters={this.showChapters.bind(this)}
                        forceUpdate0={this.forceUpdate.bind(this)}
                        chapters={this.state.chapters}
                        stringName={this.state.stringName}
                    />

                    <ListChangeLanguage
                      changeLanguage={this.changeLanguage.bind(this)} 
                    />
                    
                    <ListComment
                        myMark={this.state.myMark}
                        myMarkAlert={this.state.myMarkAlert}
                        setMyMark={this.setMyMark.bind(this)}

                        myCommentAlert={this.state.myCommentAlert}
                        setMyComment={this.setMyComment.bind(this)}

                        forceUpdate0={this.forceUpdate.bind(this)}
                        addComment={this.addComment.bind(this)}
                        stringName={this.state.stringName}
                    />


                      
                      <InsertExercise
                                          
                
                
                      sendLatex={this.sendLatex.bind(this)}
                      formulaKatex={this.state.formulaKatex}
                      
                      insertAlert={this.state.insertAlert}
                      stringName={this.state.stringName}
                    
                                      
                     />


                    <div id="clear-all-solutions-dialog" class="modal">

                        <div class="modal-content">

                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <p>{this.state.stringName.do_you_want_to_delete_all_solutions_of_the_current_user}

                                </p>
                                <button className="good2" onClick={() => {
                                    this.clearAllSolutions();
                                    var modal1 = document.getElementById("clear-all-solutions-dialog");
                                    modal1.style.display = "none";
                                }}>{this.state.stringName.yes_delete} </button>
                                <button className="close2" onClick={() => {
                                    var modal1 = document.getElementById("clear-all-solutions-dialog");
                                    modal1.style.display = "none";
                                }}>{this.state.stringName.not_stop_it} </button>
                            </div>

                            <div class="modal-footer">
                            </div>

                        </div>

                    </div>



                    <div id="insertName" class="modal">

                    <div class="modal-content">

                     <div class="modal-header">

                     </div>

                     <div class="modal-body">
                      <p className='open-class2'>{this.state.stringName.please_insert_your_full_name}
                      </p>
                      <input className="name0"
                             id="author01"
                             type='text'
                             name='author'
                             onChange={(event) => {
                               this.setState({"author": event.target.value});
                                }}
                       />
                      <button className="good2" onClick={() => {this.closeInsertName();this.insertName();                                                               
                         }}>{this.state.stringName.insert_full_name} </button>
       
                      </div>

                     <div class="modal-footer">
                     </div>

                     </div>

                     </div>


                    <div id="sendTaskToTeacher" class="modal">

                     <div class="modal-content">

                     <div class="modal-header">

                     </div>

                     <div class="modal-body">
                     <p className='open-class2'>{this.state.stringName.do_you_really_want_to_send_the_solved_mission_to_teacher}<br></br>{this.printExerciseToSend()}
                     </p>
                     <button className="good2" onClick={() => {
                             this.closeSendTaskToTeacher();
                             this.taskToTeacherDatabase();
                            }}>{this.state.stringName.yes_send_the_mission} </button>
                     <button className="close2"
                       onClick={() => this.closeSendTaskToTeacher()}>{this.state.stringName.not_stop_it} </button>
                     </div>

                     <div class="modal-footer">
                     </div>

                     </div>

                     </div>

                    <button className="chapters-btn" onClick={() => {
                       this.showChapters();
                     var modal = document.getElementById("chapter-id");
                     modal.style.display = "block";
                     }}>{this.state.stringName.collection}</button> 


                    <button className="my-settings-btn" onClick={() => {
                        var modal_list = document.getElementById("my-settings-btn-open");
                        modal_list.style.display = "block";
                    }}>{this.state.stringName.settings}</button>
                    <div id="my-settings-btn-open" class="modal-list">

                        <div class="modal-content-list">

                            <div class="modal-header-list">
                                {this.state.stringName.settings}
                            </div>

                            <div class="modal-body-list">

                                <label className="my-language-btn"> Change the language:
                                    <form>
                                        <select
                                            onChange={(event) => {
                                                this.changeLanguage(event.target.value);
                                            }}
                                            value={this.state.lang}
                                            className="my-language-btn"

                                        >
                                            <option value="en">english</option>
                                            <option value="ro"> română</option>


                                        </select>
                                    </form>
                                </label>

                                <button className="my-students-btn" onClick={() => {
                                    var modal = document.getElementById("clear-all-solutions-dialog");
                                    modal.style.display = "block";
                                    var modal_list2 = document.getElementById("my-settings-btn-open");
                                    modal_list2.style.display = "none";
                                }}> {this.state.stringName.clear_all_solutions} </button>
                                <br></br>


                                <button className="my-students-btn" onClick={() => {
                                    var modal = document.getElementById("rateApp");
                                    modal.style.display = "block";
                                    var modal_list2 = document.getElementById("my-settings-btn-open");
                                    modal_list2.style.display = "none";
                                }}> {this.state.stringName.add_a_review_to_the_application} </button>
                            </div>

                            <div class="modal-footer-list">
                                <button className="ok0" onClick={() => {
                                    var modal_list = document.getElementById("my-settings-btn-open");
                                    modal_list.style.display = "none";
                                }}>{this.state.stringName.ok}</button>
                            </div>

                        </div>

                    </div>
                      


                    <button className="my-students-btn" onClick={() => {
                                    var modal = document.getElementById("rateApp");
                                    modal.style.display = "block";
                                    var modal_list2 = document.getElementById("my-settings-btn-open");
                                    modal_list2.style.display = "none";
                                }}> {this.state.stringName.add_a_review_to_the_application} </button>   

                    <button className="out" onClick={() => this.out()}> {this.state.stringName.logout}  </button>

                    <p className="chapter0"> <img src="book.png" className='icon1' alt="" /> {this.state.chapterName} </p>

                    <header className="ContentExercises">

                        <ListExercisesA role={this.state.role} type={this.state.type} exercises={this.state.exercises}
                                        navigateTo={this.navigateTo.bind(this)}
                                        addToTask={this.addToTask.bind(this)}
                                        showSendTaskToTeacher={this.showSendTaskToTeacher.bind(this)}
                                        stringName={this.state.stringName}
                                        author={this.state.author}
                        />

                    </header>

                  
                </div>
            );
        } else if (this.state.role === "4") {
            return (
                <div className="ContentsA">

                    <ListChapters
                        getChapterContent={this.getChapterContent.bind(this)}
                        showChapters={this.showChapters.bind(this)}
                        forceUpdate0={this.forceUpdate.bind(this)}
                        chapters={this.state.chapters}
                        stringName={this.state.stringName}
                    />

                    <ChangePS
                         setOPS={this.setOPS.bind(this)}
                         setPS1={this.setPS1.bind(this)}
                         setPS2={this.setPS2.bind(this)}
                         changePSAlert={this.state.changePSAlert}
                         forceUpdate0={this.forceUpdate.bind(this)}
                         changePSF={this.changePSF.bind(this)}
                         stringName={this.state.stringName}
                    />

                    <ListChangeLanguage
                      changeLanguage={this.changeLanguage.bind(this)} 
                    />

                    <button className="chapters-btn" onClick={() => {
                       this.showChapters();
                     var modal = document.getElementById("chapter-id");
                     modal.style.display = "block";
                     }}>{this.state.stringName.collection}</button> 
     
                       <textarea className="comment"
                          rows="1" cols="15"
                          name='myComment'
                          onChange={
                              (event)=>this.setState({"myProblem": event.target.value})
                          }
                         >
                        </textarea> 

                       <button className="chapters-btn" onClick={()=>this.navigateTo(this.state.myProblem)} >{"Open problem "}</button>    

                    <div id="production-dialog" class="modal">

                        <div class="modal-content">

                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <p>{"Do you want to send into production all the solved and pending problems?"}

                                </p>
                                <button class="good" onClick={() => {
                                    this.production();
                                    var modal1 = document.getElementById("production-dialog");
                                    modal1.style.display = "none";
                                }}>{"YES send into production!"} </button>
                                <button class="close" onClick={() => {
                                    var modal1 = document.getElementById("production-dialog");
                                    modal1.style.display = "none";
                                }}>{"NO Stop it!"} </button>
                            </div>

                            <div class="modal-footer">
                            </div>

                        </div>

                    </div>


                    <div id="pending-btn-open" class="modal-list">

                        <div class="modal-content-list">

                            <div class="modal-header-list">

                            </div>

                            <div class="modal-body-list">
                                {(this.state.pending.length === 0 ? 'Pending problems do not exist' : '')}
                                {this.state.pending.map(p_i =>
                                    <div
                                        className="listTask1">{"Name:" + p_i.name + "   Chapter:" + p_i.chapter + "   Wave:" + p_i.wave + " Solved:" + p_i.solved}
                                        <button className="delete-class" onClick={() => {
                                            this.setState({markedPendingToBeDeleted: p_i.name});
                                            var modal1 = document.getElementById("delete-pending-dialog");
                                            modal1.style.display = "block";
                                            var modal2 = document.getElementById("pending-btn-open");
                                            modal2.style.display = "none";
                                        }}
                                        >{this.state.stringName.delete}
                                        </button>
                                        <button className="open-class" onClick={() => {
                                            this.openPending(p_i.name);
                                            var modal00 = document.getElementById("pendingContent");
                                            modal00.style.display = "block";
                                            var modal_list = document.getElementById("pending-btn-open");
                                            modal_list.style.display = "none";
                                            this.forceUpdate();
                                        }}>{"Open the pending problem"}</button>
                                        <button className="open-class" onClick={() => {
                                            this.navigateTo(p_i.name);
                                            var modal_list = document.getElementById("pending-btn-open");
                                            modal_list.style.display = "none";
                                            this.forceUpdate();
                                        }}>{"Solve"}</button>
                                    </div>
                                )}

                            </div>

                            <div class="modal-footer-list">
                                <button className="ok0" onClick={() => {
                                    var modal_list = document.getElementById("pending-btn-open");
                                    modal_list.style.display = "none";
                                }}>{this.state.stringName.ok}</button>
                            </div>

                        </div>

                    </div>


                    <div id="delete-pending-dialog" class="modal">

                        <div class="modal-content">

                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <p>{"Do you want to delete a pending problem and his solution too?"}

                                </p>
                                <button class="good" onClick={() => {
                                    this.deleteProblemAndSolution(this.state.markedPendingToBeDeleted);
                                    var modal1 = document.getElementById("delete-pending-dialog");
                                    modal1.style.display = "none";
                                }}>{"YES delete!"} </button>
                                <button class="close" onClick={() => {
                                    var modal1 = document.getElementById("delete-pending-dialog");
                                    modal1.style.display = "none";
                                }}>{"NO Stop it!"} </button>
                            </div>

                            <div class="modal-footer">
                            </div>

                        </div>

                    </div>


                    <div id="pendingContent" class="modal">

                        <div class="modal-content">

                            <div class="modal-header">

                            </div>

                            <div class="modal-body">


                                <label className="comment">
                                    {this.state.pendingProblem}
                                </label>

                            </div>

                            <div class="modal-footer">
                                <button className="ok0" onClick={() => {
                                    var modal = document.getElementById("pendingContent");
                                    modal.style.display = "none";
                                }}>Close
                                </button>
                            </div>

                        </div>

                    </div>


                    <InsertExercise
                                          
                    
                        
                        sendLatex={this.sendLatex.bind(this)}
                        formulaKatex={this.state.formulaKatex}
                        
                        insertAlert={this.state.insertAlert}
                        stringName={this.state.stringName}
                        
                    
                    />


                    <div id="parameters0" class="modal">

                        <div class="modal-content">

                            <div class="modal-header">

                            </div>

                            <div class="modal-body">


                                <p className="commentText"> {"Set the number of parameters"}</p>
                                <select
                                    onChange={(event) => {
                                        this.setState({"parameters": event.target.value})
                                    }}
                                    value={this.state.parameters}
                                    className="comment"
                                >
                                    <option value="2">2</option>
                                    <option value="3"> 3</option>
                                    <option value="4"> 4</option>
                                    <option value="5"> 5</option>
                                    <option value="6"> 6</option>
                                    <option value="7"> 7</option>
                                    <option value="8"> 8</option>
                                    <option value="9"> 9</option>
                                    <option value="10"> 10</option>
                                    <option value="11"> 11</option>
                                    <option value="12"> 12</option>
                                    <option value="13"> 13</option>
                                    <option value="14"> 14</option>
                                    <option value="15"> 15</option>
                                    <option value="16"> 16</option>
                                    <option value="17"> 17</option>
                                    <option value="18"> 18</option>
                                    <option value="19"> 19</option>
                                    <option value="20"> 20</option>

                                </select>

                                <button className="commentText" onClick={() => {
                                    //this.insertSymbol(this.state.choosedSymbol, this.state.parameters);
                                    var modal = document.getElementById("parameters0");
                                    modal.style.display = "none";
                                }}> Send choosed number of parameters
                                </button>

                            </div>

                            <div class="modal-footer">
                                <button className="ok0" onClick={() => {
                                    var modal = document.getElementById("parameters0");
                                    modal.style.display = "none";
                                }}>Close
                                </button>
                            </div>

                        </div>

                    </div>


                    <button className="my-finished-tasks-btn" onClick={() => {

                        var modal_list = document.getElementById("insert-d");
                        modal_list.style.display = "block";
                    }}>{"Insert source"}</button>


                    <div id="insert-d" class="modal">

                        <div class="modal-content">

                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <p className="commentText">Enter source </p>
                                <textarea className="comment"
                                          rows="5" cols="60"
                                          name='myData'
                                          onChange={(event) => {
                                              this.setState({myData: event.target.value});
                                          }}
                                >
        </textarea>


                                <p className="commentAlert"> {this.state.myDataAlert}</p>
                                <p className="commentText"> Select the type of source </p>
                                <select
                                    onChange={(event) => {
                                        this.setState({myDataType: event.target.value});
                                    }}
                                    value={this.state.myDataType}
                                    className="comment"
                                >
                                    <option value="chapters">chapters</option>
                                    <option value="en">en</option>
                                    <option value="ro"> ro</option>
                                    <option value="formulas">formulas</option>
                                    <option value="problem">problem</option>
                                </select>

                                <button className="commentText" onClick={() => {
                                    this.insertData();
                                    this.forceUpdate();
                                }}> Send the source
                                </button>

                            </div>

                            <div class="modal-footer">
                                <button className="ok0" onClick={() => {
                                    var modal = document.getElementById("insert-d");
                                    modal.style.display = "none";
                                }}>CLOSE
                                </button>
                            </div>

                        </div>

                    </div>


                    <div id="clear-all-solutions-dialog" class="modal">

                        <div class="modal-content">

                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <p className='open-class2'>{this.state.stringName.do_you_want_to_delete_all_solutions_of_the_current_user}

                                </p>
                                <button className="good2" onClick={() => {
                                    this.clearAllSolutions();
                                    var modal1 = document.getElementById("clear-all-solutions-dialog");
                                    modal1.style.display = "none";
                                }}>{this.state.stringName.yes_delete} </button>
                                <button className="close2" onClick={() => {
                                    var modal1 = document.getElementById("clear-all-solutions-dialog");
                                    modal1.style.display = "none";
                                }}>{this.state.stringName.not_stop_it} </button>
                            </div>

                            <div class="modal-footer">
                            </div>

                        </div>

                    </div>


                    <div id="info-det-btn-open" class="modal-list">

                        <div class="modal-content-list">

                            <div class="modal-header-list">
                                {"U. details: Records:" + this.state.numberOfRecords
                                    + "  Pages:" + this.state.numberOfPages
                                    + " Ratings:" + this.state.numberOfRatings
                                    + " Average rating:" + this.state.averageRating}

                                <button onClick={() => {
                                    this.downPageDetails();
                                }}>--
                                </button>
                                {"Page number:" + this.state.pageNumber}
                                <button onClick={() => {
                                    this.upPageDetails();
                                }}>++
                                </button>

                            </div>


                            <div class="modal-body-list">

                                {((this.state.details.length === 0) ? "Are no u. details" : '')}
                                {this.state.details.map(detail_i =>
                                    <div className="listClass">{"U.:" + detail_i.u1
                                        + " Name:" + detail_i.name
                                        + " Mark:" + detail_i.mark
                                        + " Comment:" + detail_i.comment}


                                        <br></br> {"Solved pro."} <br></br>
                                        {(detail_i.solvedProblems === undefined ? "Nothing solved" : ("Solv.:" + detail_i.solvedProblems.length))}
                                        {detail_i.solvedProblems !== undefined ? (detail_i.solvedProblems.map((value0) =>
                                            (" ," + value0) 
                                        )) : ""
                                        }


                                    </div>
                                )}

                            </div>


                            <div class="modal-footer-list">
                                <button className="ok0" onClick={() => {
                                    var modal_list = document.getElementById("info-det-btn-open");
                                    modal_list.style.display = "none";
                                }}>{this.state.stringName.ok}</button>
                            </div>

                        </div>

                    </div>


                    <button className="my-settings-btn" onClick={() => {
                        var modal_list = document.getElementById("my-settings-btn-open");
                        modal_list.style.display = "block";
                    }}>{this.state.stringName.settings}</button>


                         
                 <button className="my-settings-btn" onClick={() => {
                         this.getPending();
                        var modal_list = document.getElementById("pending-btn-open");
                        modal_list.style.display = "block";
                    }}>{"Pending problems"}</button>
                    
                                      
                    <button className="my-settings-btn" onClick={() => {
                        var modal_list = document.getElementById("production-dialog");
                        modal_list.style.display = "block";
                    }}>{"Insert into production"}</button>

                    <div id="my-settings-btn-open" class="modal-list">

                        <div class="modal-content-list">

                            <div class="modal-header-list">
                                {this.state.stringName.settings}
                            </div>

                            <div class="modal-body-list">

                                <label className="my-language-btn"> Change the language:
                                    <form>
                                        <select
                                            onChange={(event) => {
                                                this.changeLanguage(event.target.value);
                                            }}
                                            value={this.state.lang}
                                            className="my-language-btn"

                                        >
                                            <option value="en">english</option>
                                            <option value="ro"> română</option>


                                        </select>
                                    </form>
                                </label>

                                <button className="my-students-btn" onClick={() => {
                                    var modal = document.getElementById("clear-all-solutions-dialog");
                                    modal.style.display = "block";
                                    var modal_list2 = document.getElementById("my-settings-btn-open");
                                    modal_list2.style.display = "none";
                                }}> {this.state.stringName.clear_all_solutions} </button>
                                <br></br>

                                <button className="my-students-btn" onClick={() => {
                                    this.showDetails();
                                    var modal = document.getElementById("info-det-btn-open");
                                    modal.style.display = "block";
                                    var modal_list2 = document.getElementById("my-settings-btn-open");
                                    modal_list2.style.display = "none";
                                }}> {"U. details"} </button>

                                   <button className="out" onClick={() => this.dag0()}> {"....dag0..."}  </button> 

                                <br></br>

                                <button className="my-students-btn" onClick={() => {
                                    var modal = document.getElementById("changeps");
                                    modal.style.display = "block";
                                    var modal_list2 = document.getElementById("my-settings-btn-open");
                                    modal_list2.style.display = "none";
                                }}> {this.state.stringName.change_the_password} </button>
                                
                                <br></br>


                            </div>

                            <div class="modal-footer-list">
                                <button className="ok0" onClick={() => {
                                    var modal_list = document.getElementById("my-settings-btn-open");
                                    modal_list.style.display = "none";
                                }}>{this.state.stringName.ok}</button>
                            </div>

                        </div>

                    </div>


                    <button className="out"
                            onClick={() => this.out()}> {this.state.stringName.logout + ":" + this.fandl(this.state.username) }  </button>

                    <p className="chapter0"> <img src="book.png" className='icon1' alt="" /> {this.state.chapterName} </p>

                    <header className="ContentExercises">

                        <ListExercisesA role={this.state.role} type={this.state.type} exercises={this.state.exercises}
                                        navigateTo={this.navigateTo.bind(this)}
                                        addToTask={this.addToTask.bind(this)}
                                        showSendTaskToTeacher={this.showSendTaskToTeacher.bind(this)} 
                                        stringName={this.state.stringName}
                                        author={this.state.author}
                        />

                    </header>
                    
                    <button className="insert-exercise-button" onClick={()=>{
                        var modal1 = document.getElementById("ainsert-symbols");
                        modal1.style.display = "block";
                    }} >
                        <img src="marker.png" className='icon1' alt="" />
                    {this.state.stringName.insert_an_exercise}</button>

                   

                </div>
            );
        } else if (this.state.role === "NO") {
            return <p>Hello! You are not connected to the website!</p>
        }
    }
}

function ListExercisesA(props) {
    const exercisesA = props.exercises;
    const type = props.type;
    const role = props.role;
    const navigateTo_ = props.navigateTo;
    const addToTask_ = props.addToTask;
    const showSendTaskToTeacher_ = props.showSendTaskToTeacher;
    const author_=props.author;

    const stringName = props.stringName;

    const listExercisesA = exercisesA.map(exerciseA => {
        if ((role === "2") & (type === "1")) {
            return <div className="list" key={exerciseA.key} id={"E" + exerciseA.key} >
                <div>
                    <span className="exer"> {exerciseA.exer + "  "}</span>
                    <span className="exer"> {"," + stringName.level + ":" + exerciseA.level}</span>
                    <span className="solv"> {exerciseA.solv} </span>
                    <br></br>
                    {exerciseA.enun}
                    {(exerciseA.type2 === "EQT"|exerciseA.type2 ==="EQL"|exerciseA.type2 ==="SYS"|exerciseA.type2==="EQ2")? <div className='k1' dangerouslySetInnerHTML={{__html: exerciseA.katex}}/>:<div class="decr1"> <InlineMath>{exerciseA.katex}</InlineMath> </div> }
                    <br></br>
                    <button className="task0" onClick={() => addToTask_(exerciseA.key)}>
                        <img src="add_to_mission.png" className='icon1' alt="" />{stringName.add_to_mission} </button>
                    <button className="solve0" onClick={() => navigateTo_(exerciseA.key)}>
                        <img src="solve.png" className='icon1' alt="" />{stringName.solve} </button>
                                      

                </div>

            </div>

        } else if ((role === "2") & (type === "3")) {
            return <div>
                <div className="list" key={exerciseA.key} id={"E" + exerciseA.key} >
                    <div>
                        <span className="exer"> {exerciseA.exer + "  "}</span>
                
                        <span className="solv"> {exerciseA.solv} </span>
                        <br></br>
                        {exerciseA.enun}
                        {(true)? <div className='k1' dangerouslySetInnerHTML={{__html: exerciseA.katex}}/> : <div class="decr1"> <InlineMath>{exerciseA.katex}</InlineMath> </div>}
                         <br></br> 
                        <button className="solve0" onClick={() => navigateTo_(exerciseA.key)}>
                            <img src="solve.png" className='icon1' alt=""  />{stringName.solve} </button>
                       
                       
                    </div>
                </div>
                <ListStepsForTask steps={exerciseA.steps}/>
            </div>
        } else if (((role === "1") | (role === "3") | (role === "4")) & type === "1") {
            return <div className="list" key={exerciseA.key} id={"E" + exerciseA.key} >
                <div>
                    <span className="exer"> {exerciseA.exer + "  "}</span>
                    <span className="exer">{"," + stringName.level + ":" + exerciseA.level}</span>
                    <span className="solv"> {exerciseA.solv} </span>
                    <br></br>
                    {exerciseA.enun}
                    {(exerciseA.type2 === "EQT"|exerciseA.type2 ==="EQL"|exerciseA.type2 ==="SYS"|exerciseA.type2==="EQ2")? <div className='k1' dangerouslySetInnerHTML={{__html: exerciseA.katex}}/> : <div class="decr1"> <InlineMath>{exerciseA.katex}</InlineMath> </div>}
                    <br></br>
                    <button className="solve0" onClick={() => navigateTo_(exerciseA.key)}>
                        <img src="solve.png" className='icon1' alt="" />{stringName.solve} </button>
                   
                   


                </div>

            </div>
        } else if (((role === "1") |(role==="2")| (role === "3") | (role === "4")) & type === "4") {
            return <div className="list" key={exerciseA.key} id={"E" + exerciseA.key} >
                <div>
                    <span className="exer"> {exerciseA.exer + "  "}</span>
                    <span className="exer">{"," + stringName.level + ":" + exerciseA.level}</span>
                    <span className="solv"> {exerciseA.solv} </span>
                    <br></br>
                    {exerciseA.enun}
                    {(exerciseA.type2 === "EQT"|exerciseA.type2 ==="EQL"|exerciseA.type2 ==="SYS"|exerciseA.type2==="EQ2")? <div className='k1' dangerouslySetInnerHTML={{__html: exerciseA.katex}}/> : <div class="decr1"> <InlineMath>{exerciseA.katex}</InlineMath> </div>}
                    <br></br>
                    <button className="solve0" onClick={() => navigateTo_(exerciseA.key)}>
                        <img src="solve.png" className='icon1' alt="" />{stringName.solve} </button>
                   
                   


                </div>

            </div>
        } 
         else if (((role === "1") | (role === "2")| (role === "3")) & (type === "2")) {
            return <div>
                <div className="list" key={exerciseA.key} id={"E" + exerciseA.key} >
                    <div>
                        <span className="exer"> {exerciseA.exer + "  "}</span>
                
                        <span className="solv"> {exerciseA.solv} </span>
                        <br></br>
                        {exerciseA.enun}
                        {(true)? <div className='k1' dangerouslySetInnerHTML={{__html: exerciseA.katex}}/> : <div class="decr1"> <InlineMath>{exerciseA.katex}</InlineMath> </div>}
                         <br></br>
                        <button className="solve0" onClick={() => navigateTo_(exerciseA.key)}>
                            <img src="solve.png" className='icon1' alt="" />{stringName.solve} </button>
                       
                       
                    </div>
                </div>
                <ListStepsForTask steps={exerciseA.steps}/>
            </div>
        }

    })
    if (type === "2") {
        return <div>

            {listExercisesA}

             <span className="exer"> {stringName.author + ":" +author_} </span> 

            <button className="send-to-teacher"
                    onClick={() => showSendTaskToTeacher_()}>{stringName.send_the_solution_to_the_teacher}  </button>
        </div>
    } else if (type === "4") {
        return <div>

            {listExercisesA}            
            
        </div>
    } 
    else if ((type === "1") | (type === "3")) {

        return <div>

            {listExercisesA}

        </div>
    }
}

function ListExercisesT(props) {
    const exercises = props.exercises;
    const deleteExerciseFromTask_ = props.deleteExerciseFromTask;
    const stringName = props.stringName;


    const ListExercisesT = exercises.map(exercise => {
        return <div className="listTask" key={exercise.key}>
            <p>
                <span> {exercise.exer}</span>
                <br></br>
                {exercise.enun}
                <div className='k1' dangerouslySetInnerHTML={{__html: exercise.katex}}/>
                <button className="buttonDel"
                        onClick={() => deleteExerciseFromTask_(exercise.key)}>{stringName.delete_the_exercise}</button>


            </p>

        </div>
    })
    return <div>

        {ListExercisesT}


    </div>;
}

function ListChapters(props) {

    const stringName = props.stringName;
    const chapters = props.chapters;
    const getChapterContent_ = props.getChapterContent;
    //const showChapters_ = props.showChapters;
    const forceUpdate_ = props.forceUpdate0;


    return <div id="chapter-id" className="modal">
            <div className="modal-content">
             <div className="modal-header"></div>
             <div className="modal-body">

            {chapters.chapters.map((chapter_i, index1) =>
                <div className="chp">{(index1 + 1) + " " + stringName[chapter_i.code]}

                    {chapter_i.chapters.map((chapter_j, index2) =>
                        <div className="schp"
                             onClick={() => {
                                 getChapterContent_(chapter_j.code, ((index1 + 1) + "." + (index2 + 1) + " " + stringName[chapter_j.code]));
                                 forceUpdate_();
                                 var modal = document.getElementById("chapter-id");
                                 modal.style.display = "none";
                             }}>
                            {(index1 + 1) + "." + (index2 + 1) + " " + stringName[chapter_j.code]}

                        </div>
                    )}


                </div>
            )}
             
             </div>

            <div className="modal-footer">
            <button className="ok0" onClick={() => {
                    var modal = document.getElementById("chapter-id");
                    modal.style.display = "none";
                }}>{stringName.close}</button>
            </div>
            </div>
        </div>
    ;
}



function ListComment(props) {

    const stringName = props.stringName;

    const myCommentAlert = props.myCommentAlert;
    const setMyComment_ = props.setMyComment;

    const myMark = props.myMark;
    const myMarkAlert = props.myMarkAlert
    const setMyMark_ = props.setMyMark;

    const addComment_ = props.addComment;
    const forceUpdate_ = props.forceUpdate0;


    return <div id="rateApp" class="modal">

        <div class="modal-content">

            <div class="modal-header">

            </div>

            <div class="modal-body">
                <p className="commentText">{stringName.write_something_about_the_application}</p>
                <textarea className="comment"
                          rows="5" cols="60"
                          name='myComment'
                          onChange={(event) => {
                              setMyComment_(event)
                          }}
                >
    </textarea>


                <p className="commentAlert"> {myCommentAlert}</p>
                <p className="commentText"> {stringName.give_a_mark}</p>
                <select
                    onChange={(event) => {
                        setMyMark_(event)
                    }}
                    value={myMark}
                    className="comment"
                >
                    <option value="0">{stringName.nothing_selected}</option>
                    <option value="1"> 1</option>
                    <option value="2"> 2</option>
                    <option value="3"> 3</option>
                    <option value="4"> 4</option>
                    <option value="5"> 5</option>
                </select>
                <p className="commentAlert"> {myMarkAlert}</p>
                <button className="commentText" onClick={() => {
                    addComment_();
                    forceUpdate_();
                }}> {stringName.send_the_comment_and_mark}</button>

            </div>

            <div class="modal-footer">
                <button className="ok0" onClick={() => {
                    var modal = document.getElementById("rateApp");
                    modal.style.display = "none";
                }}>{stringName.close}</button>
            </div>

        </div>

    </div>
}

function ChangePS(props) {

    const stringName = props.stringName;

    const changePSAlert = props.changePSAlert;
    
    const setOPS_=props.setOPS;
    const setPS1_=props.setPS1;
    const setPS2_=props.setPS2;
    const changePSF_=props.changePSF;    
        
    const forceUpdate_ = props.forceUpdate0;


    return <div id="changeps" class="modal">

        <div class="modal-content">

            <div class="modal-header">

            </div>

            <div class="modal-body">
                <div className="login" >

                <p className="commentText">{stringName.change_your_password}</p>
                <p className="user_pass_text"> {stringName.type_old_password}</p>
                    <input className="user_pass"
                           type='password'
                           
                           onChange={(event) => {
                            setOPS_(event)
                        }}
                    />
                <p className="user_pass_text">{stringName.type_new_password}</p>
                    <input className="user_pass"
                           type='password'
                           
                           onChange={(event) => {
                            setPS1_(event)
                        }}
                    />
                <p className="user_pass_text">{stringName.retype_new_password}</p>
                    <input className="user_pass"
                           type='password'
                           
                           onChange={(event) => {
                            setPS2_(event)
                        }}
                    />    
                             
                <p className="commentAlert"> {changePSAlert}</p>
                <button className="commentText" onClick={() => {
                    changePSF_();
                    forceUpdate_();
                }}> {stringName.change_the_password}</button>

              </div>  

            </div>

            <div class="modal-footer">
                <button className="ok0" onClick={() => {
                    var modal = document.getElementById("changeps");
                    modal.style.display = "none";
                }}>{stringName.close}</button>
            </div>

        </div>

    </div>
}


function InsertExercise(props){
     
    const formulaKatex=props.formulaKatex;
    

    const sendLatex_=props.sendLatex;
    const insertAlert=props.insertAlert;
    const stringName=props.stringName;
    

    const mathFieldRef = useRef();
    const [latex, setLatex] = useState("2x+4=8");


  return <div id="ainsert-symbols" className="modal-list">

  <div class="modal-content-list">

      <div class="insert-exercise-alert">
          {insertAlert}
      </div>

      <div className="modal-body-list">                  
          
          <div className='edi1' id='edi_a'>
          <EditableMathField 
            mathquillDidMount={(mathField) => {
                mathFieldRef.current = mathField;
            }}
            latex={latex}
            onChange={(mathField) => {
                setLatex(mathField.latex()); 
                let scroll=document.getElementById('edi_a');
                //scroll.scrollLeft=scroll.scrollWidth;           
            }}
            onClick={()=>{document.activeElement.blur();
                          let scroll=document.getElementById('edi_a');
                          //scroll.scrollLeft=scroll.scrollWidth;
            }}
          />
         </div>
       
          <div>


           <table className="math-table" >
 
            <tr className="math-row">
            <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.cmd('[');
                            mathFieldRef.current.cmd(']');
                          }
                      }} >
                      
                  
                  <span style={{fontSize:'15px'}}>{stringName["[repeatingdecimals]"]}</span>
              </td> 
               <td></td> 
               <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.cmd('\\sqrt');
                          }
                      }} >                    
                  {"radical"}
              </td>     
               <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('?');
                          }
                      }} >
                  {"?"}
              </td>    
               <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.keystroke('Backspace');
                          }
                      }} >                      
                  <span style={{whiteSpace:'nowrap'}}>{"←Del"}</span>
                 </td> 
            </tr>
            <tr className="math-row">
            <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('<');
                          }
                      }}>     
                  {"<"}
              </td> 
              <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('>');
                          }
                      }} >
                   {">"}
              </td> 
              <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.cmd('\\leq');
                          }
                      }}  >
                   {"≤"}
              </td> 
              <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.cmd('\\geq');
                          }
                      }} >
                 {"≥"}
              </td> 
              <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.cmd('\\frac');
                          }
                      }} >                    
                  {"/"}
              </td>           
            </tr>

            <tr className="math-row">
            <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.cmd('(');
                            mathFieldRef.current.cmd(')');
                          }
                      }} >
                      
                  {"()"}
              </td> 
              <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('7');
                          }
                      }} >                  
                  {"7"}
              </td> 
              <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('8');
                          }
                      }} >                      
                  {"8"}
              </td> 
              <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('9');
                          }
                      }} >                     
                  {"9"}
              </td> 
              <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('\\cdot');
                          }
                      }} >                     
                  {"*"}
              </td> 
            </tr>
            <tr className="math-row">
            <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('^{}');
                          }
                      }} >                      
                   <span style={{whiteSpace:'nowrap'}}> a <sup>b</sup> </span>

            </td> 
            <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('4');
                          }
                      }} >                     
                  {"4"}
              </td> 
              <td className="math-symbol"  onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('5');
                          }
                      }} >                    
                  {"5"}
              </td> 
              <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('6');
                          }
                      }} >
                  {"6"}
              </td>   
              <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('-');
                          }
                      }}  >
                            
                  {"-"}
              </td> 
            </tr>            
            <tr className="math-row">
            <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.moveToLeftEnd();
                            let scroll=document.getElementById('edi_a');
                            scroll.scrollLeft=-scroll.scrollWidth;
                          }
                      }} >                      
                   <span style={{whiteSpace:'nowrap'}}>{"|<-"}</span>
                 </td>
            <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('1');
                          }                        
                      }} >                     
                  {"1"}
              </td> 
              <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('2');
                          }
                      }} >                      
                  {"2"}
              </td>   
              <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('3');
                          }
                      }} >                     
                  {"3"}
              </td> 
              <td className="math-symbol"  onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('+');
                          }
                      }} >
                  {"+"}
              </td> 
            </tr>
            <tr className="math-row">
            <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.moveToRightEnd();
                            let scroll=document.getElementById('edi_a');
                            scroll.scrollLeft=scroll.scrollWidth;
                          }
                      }} >                      
                   <span style={{whiteSpace:'nowrap'}}>{"->|"}</span>
                 </td> 
            <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('x');
                          }
                      }} >                      
                  {"x"}
                 </td> 
            <td className="math-symbol"  onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('0');
                          }
                    }} >                      
                  {"0"}
              </td> 
            <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.write('.');
                          }
                      }} >                     
                  {stringName["point"]}
              </td> 
            <td className="math-symbol" onClick={(event) => {
                          if(mathFieldRef.current) {
                            mathFieldRef.current.write('=');
                          }
                      }} >
                  {"="}
              </td> 
            </tr>
                                 
           </table>

          
          </div>

          <button className="math-done" onClick={(event) => {
                          sendLatex_(latex)
                      }} >                       
                  {stringName["done"]}
          </button>

          

      </div>

      <div class="modal-footer-list">
          <button className="ok0" onClick={() => {
              var modal_list = document.getElementById("ainsert-symbols");
              modal_list.style.display = "none";
          }}> {stringName["close"]} </button>
      </div>

  </div>

</div> 
  
  
}


function InsertChoice(props){

    const sendLatexChoice_=props.sendLatexChoice;
    const sendChoice_=props.sendChoice;
    const choiceFormula=props.choiceFormula;
    const formulaKatex=props.formulaKatex;
    const choiceContext=props.choiceContext;
    const choicesKatex=props.choicesKatex;
    const insertChoiceAlert=props.insertChoiceAlert;
    const stringName=props.stringName;   
    

    const mathFieldRef = useRef();
    const [latex, setLatex] = useState("3");

  if(choicesKatex.length===0)
  return ( <div  id="show-choice" className="modal-list">

  <div class="modal-content-list">

      <div class="insert-exercise-alert">
          {insertChoiceAlert}
      </div>

      <div className="modal-body-list">

            <p> {stringName[choiceFormula] + ":"} </p><br></br>
            <div className='k3' dangerouslySetInnerHTML={{__html: choiceContext}}/> <br></br>

            <label>  {stringName[(choiceFormula + "_b")] + ":"}   </label>
            <br></br>
          <div className='edi1' id='edi_b'>        
          <EditableMathField 
            mathquillDidMount={(mathField) => {
                mathFieldRef.current = mathField;
            }}
            latex={latex}
            onChange={(mathField) => {
                setLatex(mathField.latex()); 
                //document.activeElement.blur();           
            }}
            onClick={()=>{document.activeElement.blur()}}
          />
          </div>
       
          <div>


          <table className="math-table" >
 
 <tr className="math-row">
 <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.cmd('[');
                 mathFieldRef.current.cmd(']');
               }
           }} >
           
       
       <span style={{fontSize:'15px'}}>{stringName["[repeatingdecimals]"]}</span>
   </td> 
    <td></td> 
    <td></td> 
    <td></td> 
    <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.keystroke('Backspace');
               }
           }} >                      
       <span style={{whiteSpace:'nowrap'}}>{"←Del"}</span>
      </td> 
 </tr>
 <tr className="math-row">
 <td></td>
 <td></td>
 <td></td>
 <td></td>
   <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.cmd('\\frac');
               }
           }} >                    
       {"/"}
   </td>           
 </tr>

 <tr className="math-row">
 <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.cmd('(');
                 mathFieldRef.current.cmd(')');
               }
           }} >
           
       {"()"}
   </td> 
   <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.write('7');
               }
           }} >                  
       {"7"}
   </td> 
   <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.write('8');
               }
           }} >                      
       {"8"}
   </td> 
   <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.write('9');
               }
           }} >                     
       {"9"}
   </td> 
   <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.write('\\cdot');
               }
           }} >                     
       {"*"}
   </td> 
 </tr>
 <tr className="math-row">
 <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.write('^{}');
               }
           }} >                      
        <span style={{whiteSpace:'nowrap'}}> a <sup>b</sup> </span>

 </td> 
 <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.write('4');
               }
           }} >                     
       {"4"}
   </td> 
   <td className="math-symbol"  onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.write('5');
               }
           }} >                    
       {"5"}
   </td> 
   <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.write('6');
               }
           }} >
       {"6"}
   </td>   
   <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.write('-');
               }
           }}  >
                 
       {"-"}
   </td> 
 </tr>            
 <tr className="math-row">
 <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.moveToLeftEnd();
                 let scroll=document.getElementById('edi_a');
                 scroll.scrollLeft=-scroll.scrollWidth;
               }
           }} >                      
        <span style={{whiteSpace:'nowrap'}}>{"|<-"}</span>
      </td>
 <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.write('1');
               }                        
           }} >                     
       {"1"}
   </td> 
   <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.write('2');
               }
           }} >                      
       {"2"}
   </td>   
   <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.write('3');
               }
           }} >                     
       {"3"}
   </td> 
   <td className="math-symbol"  onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.write('+');
               }
           }} >
       {"+"}
   </td> 
 </tr>
 <tr className="math-row">
 <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.moveToRightEnd();
                 let scroll=document.getElementById('edi_a');
                 scroll.scrollLeft=scroll.scrollWidth;
               }
           }} >                      
        <span style={{whiteSpace:'nowrap'}}>{"->|"}</span>
      </td> 
 <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.write('x');
               }
           }} >                      
       {"x"}
      </td> 
 <td className="math-symbol"  onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.write('0');
               }
         }} >                      
       {"0"}
   </td> 
 <td className="math-symbol" onClick={(event) => {
             if(mathFieldRef.current) {
                 mathFieldRef.current.write('.');
               }
           }} >                     
       {stringName["point"]}
   </td> 
   <td className="math-symbol" onClick={(event) => {
                        if(mathFieldRef.current) {
                            mathFieldRef.current.cmd('\\sqrt');
                          }
                      }} >                    
                  {"radical"}
    </td>     
 </tr>
                      
</table>


          
          </div>

          <button className="math-done" onClick={(event) => {
                          sendLatexChoice_(latex)
                      }} >                       
                  {stringName["done"]}
          </button>

        

      </div>

      <div class="modal-footer-list">
          <button className="ok0" onClick={() => {
              var modal_list = document.getElementById("show-choice");
              modal_list.style.display = "none";
          }}> {stringName["close"]} </button>
      </div>

  </div>

</div>)

else{ return( <div  id="show-choice" className="modal-list">

<div class="modal-content-list">

    <div class="insert-exercise-alert">
        {insertChoiceAlert}
    </div>

    <div className="modal-body-list">

          <p> {stringName[choiceFormula] + ":"} </p><br></br>
          <div className='k3' dangerouslySetInnerHTML={{__html: choiceContext}}/> <br></br>

          <label>  {stringName[(choiceFormula + "_b")] + ":"}   </label>
          <br></br>
      
                         <select
                            onChange={(event) => {
                                console.log("sel val 1:"+event.target.value);
                                sendChoice_(event.target.value);
                            }}
                            value={-1}
                            className="choice_class"
                        >                 
                            <option className="option_choice_class" value={-1}> {stringName["nothing_selected"]}</option>
                            {choicesKatex.map((value0,index)=> <option className="option_choice_class" value={index}><div className='k3' dangerouslySetInnerHTML={{__html: value0}}/></option>  )}


                        </select>
            
   

      
      

    </div>

    <div class="modal-footer-list">
        <button className="ok0" onClick={() => {
            var modal_list = document.getElementById("show-choice");
            modal_list.style.display = "none";
        }}> {stringName["close"]} </button>
    </div>

</div>

</div> ) }
  
  
}

function ListChangeLanguage(props) {

   const changeLanguage_=props.changeLanguage;



    return <div id="change-language-dialog" class="modal">

    <div class="modal-content">
    
    <div class="modal-header">
    
    </div>
    
    <div class="modal-body">
    <p> Please choose a language:
    </p>
    <button class="choose-lang" onClick={() => {
      changeLanguage_("en");
     var modal1 = document.getElementById("change-language-dialog");
     modal1.style.display = "none";
       }}> english </button>
    <br></br>
    <button class="choose-lang" onClick={() => {
     changeLanguage_("ro");
     var modal1 = document.getElementById("change-language-dialog");
     modal1.style.display = "none";
       }}> română </button>
    </div>
    
    <div class="modal-footer">
    </div>
    
    </div>
    
    </div>   ; 
     
}



class SolverA extends React.Component {
    constructor(props) {
        super(props);
        window.onbeforeunload = exitFromSolver;
       

        function exitFromSolver() {

            const data = {message: "EXIT_FROM_SOLVER", rr: obtainValue()};
            fetch(WebService1, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(json => {

                })
                .catch(err => console.log(err));

        }

        this.state = {role: "NO"};
    
        const data = {message: "GET_SOLVER_A_UI", rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);


                this.setState(json, () => {
                    this.arrangePage(this);
                    this.drawGeometry(this);
                    this.remoId();
                    this.remoId();
                });
            })
            .catch(err => console.log(err));
      

    }

    get2dPoint(points2d0, name0) {
        if (points2d0 !== undefined) {
            let n0 = points2d0.length;
            for (let index = 0; index < n0; index++) {
                if (points2d0[index].n === name0) {
                    return points2d0[index];
                }

            }
        }
    }

    drawGeometry(state00) {

        if (state00.state.role === "GEO_2D") {
            console.log("Draw State Geom:" + JSON.stringify(state00.state.geometry));
            var canvas = document.getElementById("canvas0");
            var ctx = canvas.getContext("2d");
            let totalWidth = window.innerWidth;
            let scale=2;
            if(totalWidth<=1700){
                scale=2;
            }else{scale=1;}

            ctx.clearRect(0, 0, 500*scale, 250*scale);
            ctx.lineWidth = 4;

            var lines0 = state00.state.geometry.lines;
            var points2d0 = state00.state.geometry.points2d;
            var arcs0 = state00.state.geometry.arcs;

            lines0.map((value) => {
                let p1 = value.l[0];
                let p2 = value.l[1];
                let color0 = value.c;

                let p1c = this.get2dPoint(points2d0, p1);
                let p2c = this.get2dPoint(points2d0, p2);

                ctx.beginPath();
                ctx.moveTo(p1c.x*scale, p1c.y*scale);
                ctx.lineTo(p2c.x*scale, p2c.y*scale);
                ctx.strokeStyle = color0;

                ctx.stroke();


            });


            arcs0.map((value0) => {
                if (value0.s === 1) {//if show is 1 then we show
                    let p1 = value0.a[0];
                    let p2 = value0.a[1];
                    let p3 = value0.a[2];
                    console.log("Draw arc:" + value0.a[0] + "" + value0.a[1] + "" + value0.a[2]);
                    let color0 = value0.c;
                    let measure0 = value0.m;
                    let radius0 = value0.r;
                    let p1c = this.get2dPoint(points2d0, p1);
                    let p2c = this.get2dPoint(points2d0, p2);
                    let p3c = this.get2dPoint(points2d0, p3);
                    var nx1 = p1c.x*scale - p2c.x*scale;
                    var ny1 = p1c.y*scale - p2c.y*scale;
                    var nx2 = p3c.x*scale - p2c.x*scale;
                    var ny2 = p3c.y*scale - p2c.y*scale;
                    let angle1 = Math.atan2(ny1, nx1);
                    let angle2 = Math.atan2(ny2, nx2);
                    // use cross product to find correct direction.
                    if (nx1 * ny2 - ny1 * nx2 < 0) { // wrong way around swap direction
                        const t = angle2;
                        angle2 = angle1;
                        angle1 = t;
                    }
                    // if angle 1 is behind then move ahead
                    if (angle1 < angle2) {
                        angle1 += Math.PI * 2;
                    }
                    ctx.beginPath();
                    ctx.arc(p2c.x*scale, p2c.y*scale, radius0*scale, angle1, angle2);
                    ctx.strokeStyle = color0;

                    ctx.stroke();

                    if (value0.p === 1) {//is arc is permanent then it has measure
                        ctx.beginPath();
                        ctx.font = (12*scale)+"px Arial";
                        ctx.fillStyle = color0;
                        ctx.fillText(measure0 + "°",
                            p2c.x*scale + (radius0*scale + radius0*scale / 3.5) * Math.cos(Math.PI + angle2 + (angle1 - angle2) / 2) - (10),
                            p2c.y*scale + (radius0*scale + radius0*scale / 3.5) * Math.sin(Math.PI + angle2 + (angle1 - angle2) / 2) + 2);

                        //ctx.fill();

                    }

                    //ctx.strokeStyle=color0;
                    //ctx.stroke();

                } else {

                }
            });

            points2d0.map((value) => {

                ctx.beginPath();
                ctx.font = (scale*20)+"px Arial";
                ctx.fillStyle = "green";
                if (value.o === "l") {
                    ctx.fillText(value.n, value.x*scale - 15*scale, value.y*scale );
                } else if (value.o === "r") {
                    ctx.fillText(value.n, value.x*scale  + 10*scale, value.y*scale );
                } else if (value.o === "u") {
                    ctx.fillText(value.n, value.x*scale , value.y*scale  - 20*scale);
                } else if (value.o === "d") {
                    ctx.fillText(value.n, value.x*scale , value.y*scale  + 20*scale);
                }

                //ctx.fill();


            })
           

        }

    }


    getfChapterContent(chapter1) {
        const data = {message: "GET_F_CHAPTER_CONTENT", chapter:chapter1, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                this.setState(json, () => {
                    this.arrangePage(this);
                    this.colouring_border(this);
                    this.goToSelectedStep(this);
                    this.drawGeometry(this);
                    //this.addOnClickFunction();
                    this.remoId();
                    this.remoId();
                    if (this.state.stageDialogActivated === true) {
                        this.displayStageDialog();
                    }
                });
                

            })
            .catch(err => console.log(err));
    }
    whereToApply(formula1) {
        const data = {message: "SOLVER_A_WHERE", formula: formula1, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);

                this.setState(json, () => {
                    this.arrangePage(this);
                    this.colouring_border(this);
                    this.goToSelectedStep(this);
                    this.drawGeometry(this);
                    
                    if (this.state.stageDialogActivated === true) {
                        this.displayStageDialog();
                    }
                   
                    this.addOnClickFunction();
                });
            })
            .catch(err => console.log(err));
    }
  
    populateMath(){

    }

   //.removeAttribute('id');

   remoId(){
    for(let i0=0;i0<60;i0++){
        let el1=document.getElementById("zf"+i0);
        if(el1!==null){el1.removeAttribute('id');}
    }

   }
    addOnClickFunction(){
        for(let i0=0;i0<60;i0++){
            let el1=document.getElementById("zf"+i0);
            

            let el_drag_left=document.getElementById("X_L_"+i0);
            let el_drag_right=document.getElementById("X_R_"+i0);

            let el_drop_left=document.getElementById("Y_L_"+i0);
            let el_drop_right=document.getElementById("Y_R_"+i0);
            
            if(el1!==null&el1!==undefined){
            el1.onclick =(event)=> {this.on_zone(event);}
            console.log("OnClick fun. :"+i0+" id:"+el1.id);
            }

            if(el_drag_left!==null&el_drag_left!==undefined){
                el_drag_left.onclick=(event)=>{this.on_rectangle(event);}
                
            }
            if(el_drag_right!==null&el_drag_right!==undefined){

                el_drag_right.onclick=(event)=>{this.on_rectangle(event);}
               
               
            }
            if(el_drop_left!==null&el_drop_left!==undefined){
                el_drop_left.onclick=(event)=>{this.on_underline(event);}
               
                
            }
            if(el_drop_right!==null&el_drop_right!==undefined){
                el_drop_right.onclick=(event)=>{this.on_underline(event);}
               
              
            }

        }  
        
        for(let i1=0;i1<100;i1++){
        for(let j1=0;j1<100;j1++){
           

            let el_drag=document.getElementById("X_S"+i1+"_"+j1);
            

            let el_drop=document.getElementById("Y_S"+i1+"_"+j1);
            
            
           
            if(el_drag!==null&el_drag!==undefined){
                el_drag.onclick=(event)=>{this.on_rectangle(event);}                
            }
            
            if(el_drop!==null&el_drop!==undefined){
                el_drop.onclick=(event)=>{this.on_underline(event);}                              
            }
            

        }
        }

    }

   
    selectLocation(location1) {
        const data = {message:"SOLVER_SELECT_LOCATION" ,location:location1, rr: obtainValue()};
        
        console.log("Init sel loc y:");
        let formulaType="";
        let f1="";

         f1=this.state.formulasAll[this.state.selectedFormula];
         
         console.log("Sel for "+this.state.selectedFormula)
         console.log("curr for "+f1);

         formulaType=f1.t;
         console.log("for type "+formulaType);

         
        if(formulaType==="S"){
          
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);

                this.setState(json, () => {
                    this.arrangePage(this);
                    this.colouring_border(this);
                    this.goToSelectedStep(this);
                    this.drawGeometry(this);
                    this.remoId();
                    this.remoId();
                    this.remoId();
                    this.remoId();
                    //this.addOnClickFunction();
                });

                if (this.state.isSolved === true) {

                    if (this.state.showedCongra === false) {

                        this.showCongratulations();


                    }

                }

                if (this.state.noStepSelected !== undefined) {
                    if (this.state.noStepSelected === true) {
                        this.displayNoStepSelected();
                    }
                }

                if (this.state.isAlreadyGenerated !== undefined) {
                    if (this.state.isAlreadyGenerated === true) {
                        this.displayIsAlreadyGeneratedStep();
                    }
                }

            })
            .catch(err => console.log(err));
        }else if(formulaType==="SC"){
            this.getMyChoice(this.state.selectedFormula,location1);
            this.showChoice();
            this.setState({"selectedLocationOfFormula":location1});
            this.remoId();
            this.remoId();
        }
       

    }
    
    on_zone(ev){
        
        console.log("ev recta:"+ev.currentTarget.id);
        //let x=document.getElementById(ev.currentTarget.id);
        //x.style.color="orange";
       
        
        let data=ev.currentTarget.id;
        let a1=0;
        if(data!==null&data!==undefined&data!==""){
          let final1=data.length;    
          a1=parseInt(data.substring(2,final1));     

        }
         console.log("on_zon a1:"+a1);    
         this.selectLocation(a1); 

       }

     on_rectangle(ev){
        
        console.log("ev recta:"+ev.currentTarget.id);
        //let x=document.getElementById(ev.currentTarget.id);
        //x.style.color="orange";
       
        
        let data=ev.currentTarget.id;
        let a1="",a2="",a3="";
        if(data!==null&data!==undefined&data!==""){
        
        /*
        let final1=data.length;    
        a1=data.substring(0,1);
        a2=data.substring(1,2);
        a3=parseInt(data.substring(2,final1));
        */
        const array=data.split("_");
        a1=array[0];
        a2=array[1];
        a3=array[2];

        }
         console.log("on_rec a1:"+a1+"a2:"+a2+"a3:"+a3);    
         this.drag1(a2,a3); 

       }
    

    on_underline(ev){
        //let x=document.getElementById(ev.currentTarget.id);   
        //x.style.backgroundColor="orange";
           
        
        let data2=ev.currentTarget.id;
        console.log("drop:"+data2);
        
        let b1="",b2="",b3="";
        if(data2!==null&data2!==undefined&data2!==""){
        /*
        let final2=data2.length;    
        b1=data2.substring(0,1);
        b2=data2.substring(1,2);
        b3=parseInt(data2.substring(2,final2));
        */
        const array=data2.split("_");
        b1=array[0];
        b2=array[1];
        b3=array[2];

        }
        console.log("on_un b1:"+b1+"b2:"+b2+"b3:"+b3);   
        this.drop2(b2,b3);
    
        
    }
  
   

    drawSelected(key0) {
        const data = {message: "SOLVER_DRAW_SELECTED", key: key0, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);

                this.setState(json, () => {
                    this.arrangePage(this);
                    this.drawGeometry(this);
                });
            })
            .catch(err => console.log(err));
    }


    delete(key1) {
        const data = {message: "SOLVER_A_DELETE", key: key1, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);

                this.setState(json, () => {
                    this.arrangePage(this);
                    this.colouring_border(this);
                    this.goToLastStep(this);
                    this.drawGeometry(this);
                    this.remoId();
                    this.remoId();
                });
            })
            .catch(err => console.log(err));
    }

    selectSt(selected1) {
        const data = {message: "SOLVER_SELECT_STATEMENT", selected: selected1, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);

                this.setState(json, () => {
                    this.arrangePage(this);
                    this.colouring_border(this);
                    this.drawGeometry(this);
                    this.goToSelectedStep(this);
                    this.addOnClickFunction();
                });

                if (this.state.isSolved === true) {

                    if (this.state.showedCongra === false) {

                        this.showCongratulations();


                    }

                }

                if (this.state.noStepSelected !== undefined) {
                    if (this.state.noStepSelected === true) {
                        this.displayNoStepSelected();
                    }
                }

                if (this.state.isAlreadyGenerated !== undefined) {
                    if (this.state.isAlreadyGenerated === true) {
                        this.displayIsAlreadyGeneratedStep();
                    }
                }


            })
            .catch(err => console.log(err));
    }
   
    drag1(startSide1,startIndex1) {
        const data = {message: "SOLVER_A_DRAG1", 
                    
                     startSide:startSide1,
                     startIndex:startIndex1,
                     rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);

                this.setState(json, () => {
                    this.arrangePage(this);
                    this.colouring_border(this);
                    this.goToSelectedStep(this);
                    this.remoId();
                    this.remoId();
                    this.addOnClickFunction();
                });

                if (this.state.isSolved === true) {

                    if (this.state.showedCongra === false) {

                        this.showCongratulations();


                    }

                }

                if (this.state.noStepSelected !== undefined) {
                    if (this.state.noStepSelected === true) {
                        this.displayNoStepSelected();
                    }
                }

                if (this.state.isAlreadyGenerated !== undefined) {
                    if (this.state.isAlreadyGenerated === true) {
                        this.displayIsAlreadyGeneratedStep();
                    }
                }

            })
            .catch(err => console.log(err));
    }

    drop2(destinationSide1,destinationIndex1) {
        const data = {message: "SOLVER_A_DROP2", 
                     
                     destinationSide:destinationSide1,
                     destinationIndex:destinationIndex1,
                     rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);

                this.setState(json, () => {
                    this.arrangePage(this);
                    this.colouring_border(this);
                    this.goToSelectedStep(this);
                    //this.addOnClickFunction();
                    this.remoId();
                    this.remoId();
                });

                if (this.state.isSolved === true) {

                    if (this.state.showedCongra === false) {

                        this.showCongratulations();


                    }

                }

                if (this.state.noStepSelected !== undefined) {
                    if (this.state.noStepSelected === true) {
                        this.displayNoStepSelected();
                    }
                }

                if (this.state.isAlreadyGenerated !== undefined) {
                    if (this.state.isAlreadyGenerated === true) {
                        this.displayIsAlreadyGeneratedStep();
                    }
                }

            })
            .catch(err => console.log(err));
    }

    sendLatexChoice(latex1) {
        this.closeChoice();
        const data = {message: "SEND_LATEX_CHOICE", 
        formula: this.state.choice.formula,
        location:this.state.selectedLocationOfFormula ,
        latex: latex1,
        rr: obtainValue()};
        
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);

                this.setState(json, () => {
                    this.arrangePage(this);
                    this.colouring_border(this);
                    this.goToLastStep(this);
                    if(this.state.insertChoiceOpened===true){
                        var modal1 = document.getElementById("show-choice");
                     //Open the modal
                     modal1.style.display = "block";
                    }else if(this.state.insertChoiceOpened===false){
                        var modal2 = document.getElementById("show-choice");
                     //Open the modal
                     modal2.style.display = "none";
                    }

                    this.remoId();
                    this.remoId();
                });

                if (this.state.isSolved === true) {

                    if (this.state.showedCongra === false) {

                        this.showCongratulations();


                    }

                }


            })
            .catch(err => console.log(err));


    }


    sendChoice(choice1) {
        this.closeChoice();
        const data = {message: "SEND_CHOICE", 
        formula: this.state.choice.formula,
        location:this.state.selectedLocationOfFormula ,
        choice: choice1,
        rr: obtainValue()};
        
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);

                this.setState(json, () => {
                    this.arrangePage(this);
                    this.colouring_border(this);
                    this.goToLastStep(this);
                    if(this.state.insertChoiceOpened===true){
                        var modal1 = document.getElementById("show-choice");
                     //Open the modal
                     modal1.style.display = "block";
                    }else if(this.state.insertChoiceOpened===false){
                        var modal2 = document.getElementById("show-choice");
                     //Open the modal
                     modal2.style.display = "none";
                    }

                    this.remoId();
                    this.remoId();
                });

                if (this.state.isSolved === true) {

                    if (this.state.showedCongra === false) {

                        this.showCongratulations();


                    }

                }


            })
            .catch(err => console.log(err));


    }

    
    resetFormulas() {
        const data = {message: "RESET_FORMULAS", rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);

                this.setState(json, () => {
                    this.arrangePage(this);
                    this.colouring_border(this);
                    this.goToSelectedStep(this);
                    this.remoId();
                    this.remoId();
                });
            })
            .catch(err => console.log(err));
    }

    getMyChoice(formula1,location1) {
        const data = {message: "GET_MY_CHOICE", formula: formula1,location:location1, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json);

            })
            .catch(err => console.log(err));
    }
    

    goToLastStep(state00) {

        let last = state00.state.steps.length - 1;
        let lastKey = state00.state.steps[last].key;
        let el1 = document.getElementById("S" + lastKey);
        if (el1 !== null) {
            el1.scrollIntoView({block: "nearest", inline: "nearest"});
        }
        ;
    }

    goToSelectedStep(state00) {

        let selectedKey = state00.state.selectedStm;
        let el1 = document.getElementById("S" + selectedKey);
        if (el1 !== null) {
            el1.scrollIntoView({block: "nearest", inline: "nearest"});
        }
        ;
    }

    colouring_border(state00) {
        let firstStepWithCheckBox = -1;
        if (state00.state.steps !== undefined) {
            state00.state.steps.map((value) => {
                if (value.checkType === 2) {
                    if (firstStepWithCheckBox < 0) {
                        firstStepWithCheckBox = value.key;
                    }
                    let el0 = document.getElementById(("S" + value.key));
                    if (el0 !== null) {
                        el0.style.borderColor = "orange";
                        el0.style.backgroundColor = "white";
                        console.log("El sel:" + value.key)
                    }
                    ;

                } else if (value.checkType === 3) {
                    if (firstStepWithCheckBox < 0) {
                        firstStepWithCheckBox = value.key;
                    }
                    let el0 = document.getElementById(("S" + value.key));
                    if (el0 !== null) {
                        el0.style.borderColor = "green";
                        el0.style.backgroundColor = "white";
                        console.log("El sel:" + value.key)
                    }
                    ;

                } else if (value.checkType === 1) {

                    let el0 = document.getElementById(("S" + value.key));
                    if (el0 !== null) {
                        el0.style.borderColor = "white";
                        el0.style.backgroundColor = "white";
                        console.log("El sel:" + value.key)
                    }
                    ;

                }
            })
        }
        if (state00.state.formulas !== undefined) {
            state00.state.formulas.map((value2) => {
                if (value2.key === state00.state.selectedFormula) {
                    let el0 = document.getElementById(("F" + value2.key));
                    if (el0 !== null) {
                        el0.style.borderColor = "green";
                        el0.style.backgroundColor = "white";
                    }
                    ;

                } else {
                    let el0 = document.getElementById(("F" + value2.key));
                    if (el0 !== null) {
                        el0.style.borderColor = "white";
                        el0.style.backgroundColor = "white";
                    }
                    ;
                }
            })
        }

        //if  first step is greated then zero with scroll to it
        if (firstStepWithCheckBox > 0) {
            let el1 = document.getElementById("S" + firstStepWithCheckBox);
            if (el1 !== null) {
                el1.scrollIntoView({block: "nearest", inline: "nearest"});
            }
            ;
        }

        this.goToSelectedStep(state00);

    }

    showCongratulations() {
        //wait and show Congratulations
        setTimeout(this.delayedCongratulations, 800)
    }

    delayedCongratulations() {
        // Get the modal
        var modal = document.getElementById("myModal");
        //Open the modal
        modal.style.display = "block"

    }


    closeCongratulations() {

        //we clear variables for showing congratulations

        this.setState({isSolved: false});
        this.setState({showedCongra: false});

        var modal = document.getElementById("myModal");
        modal.style.display = "none";
        window.location = '/';

    }

    displayNoStepSelected() {
        // Get the modal
        var modal = document.getElementById("noStepSelected");
        //Open the modal
        modal.style.display = "block"

    }

    closeNoStepSelected() {


        var modal = document.getElementById("noStepSelected");
        modal.style.display = "none";


    }


    displayStageDialog() {
        // Get the modal
        var modal = document.getElementById("stageDialog");
        //Open the modal
        modal.style.display = "block"

    }

    closeStageDialog() {


        var modal = document.getElementById("stageDialog");
        modal.style.display = "none";


    }


    displayIsAlreadyGeneratedStep() {
        // Get the modal
        var modal = document.getElementById("isAlreadyGeneratedStep");
        //Open the modal
        modal.style.display = "block"

    }

    closeIsAlreadyGeneratedStep() {

        this.setState({isAlreadyGenerated: false});

        var modal = document.getElementById("isAlreadyGeneratedStep");
        modal.style.display = "none";


    }

    arrangePage(state00) {
        let totalHeight = window.innerHeight;
        let totalWidth = window.innerWidth;
        let drawHeight = 250*this.calculateScale();
        console.log("Scale:"+this.calculateScale());
        let scriptsHeight = 120*this.calculateScale();

        if (state00.state.role !== "GEO_2D" & state00.state.role !== "GEO_3D") {
            drawHeight = 0;
        }
        console.log("draw height:"+drawHeight);
        let scrollHeight1 = Math.round((totalHeight - drawHeight - scriptsHeight) * 2 / 3)-400;
        let scrollHeight2 = Math.round((totalHeight - drawHeight - scriptsHeight) / 3);


        document.getElementById("SolverSteps0").style.minHeight = (scrollHeight1 + "px");

        document.getElementById("SolverFormulas0").style.minHeight = (scrollHeight2 + "px");

        var stepElements = document.getElementsByClassName("list2");
        var formulaElements = document.getElementsByClassName("list3");


        var where2Elements = document.getElementsByClassName("where2");
        var apply2Elements = document.getElementsByClassName("apply2");
        var dragg2Elements = document.getElementsByClassName("dragg2");


        var next2Elements = document.getElementsByClassName("next2");
        
        var notnow2Elements = document.getElementsByClassName("notnow2");
        var xElements = document.getElementsByClassName("x");
        var step0Elements = document.getElementsByClassName("step");
        var icon2Elements = document.getElementsByClassName("icon2");
        var congratulations2Elements = document.getElementsByClassName("congra2");
        var close2Elements = document.getElementsByClassName("close");
        var goal2Elements = document.getElementsByClassName("goal");
        var check2Elements = document.getElementsByClassName("checkb");
        var modalBody2Elements = document.getElementsByClassName("modal-body");
        var k2Elements = document.getElementsByClassName("k2");
        var k3Elements = document.getElementsByClassName("k3");
        var math2=document.getElementsByClassName("math2");
        var mathDone=document.getElementsByClassName("math-done");
        var body3=document.getElementsByClassName("modal-body-list");
        var header3=document.getElementsByClassName("modal-header-list");
        var fchpbut1=document.getElementsByClassName("fchpbut");
        var choice2Elements = document.getElementsByClassName("choice_class");
        var option2Elements = document.getElementsByClassName("option_choice_class");

        if (totalWidth <= 1700) {

            for (let i = 0; i < stepElements.length; i++) {
                stepElements[i].style.height = '275px';
                stepElements[i].style.fontSize = '48px';
                stepElements[i].style.borderWidth = '8px';

            }

            for (let i = 0; i < formulaElements.length; i++) {
                formulaElements[i].style.height = '350px';
                formulaElements[i].style.fontSize = '48px';
                formulaElements[i].style.borderWidth = '8px';


            }

            for (let i = 0; i < where2Elements.length; i++) {
                where2Elements[i].style.fontSize = '48px';
            }
            for (let i = 0; i < apply2Elements.length; i++) {
                apply2Elements[i].style.fontSize = '48px';
            }

            for (let i = 0; i < dragg2Elements.length; i++) {
                dragg2Elements[i].style.fontSize = '48px';
            }

            for (let i = 0; i < next2Elements.length; i++) {
                next2Elements[i].style.fontSize = '48px';
            }
            
            for (let i = 0; i < notnow2Elements.length; i++) {
                notnow2Elements[i].style.fontSize = '48px';
            }
            for (let i = 0; i < xElements.length; i++) {
                xElements[i].style.fontSize = '48px';
            }
            for (let i = 0; i < step0Elements.length; i++) {
                step0Elements[i].style.fontSize = '48px';
            }

            for (let i = 0; i < icon2Elements.length; i++) {
                icon2Elements[i].style.height = '130px';
            }

            for (let i = 0; i < congratulations2Elements.length; i++) {
                congratulations2Elements[i].style.fontSize = '80px';
            }

            for (let i = 0; i < close2Elements.length; i++) {
                close2Elements[i].style.fontSize = '50px';
            }

            for (let i = 0; i < goal2Elements.length; i++) {
                goal2Elements[i].style.fontSize = '50px';
            }

            for (let i = 0; i < check2Elements.length; i++) {
                check2Elements[i].style.height='48px'
                check2Elements[i].style.width = '48px';                
            }
            
            for (let i = 0; i < modalBody2Elements.length; i++) {
                modalBody2Elements[i].style.fontSize = '48px';
            }
            
            for (let i = 0; i < k2Elements.length; i++) {
                k2Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < k3Elements.length; i++) {
                k3Elements[i].style.fontSize = '52px';
            }

            for (let i = 0; i < math2.length; i++) {
                math2[i].style.padding = '30px';
            }
            for (let i = 0; i < mathDone.length; i++) {
                mathDone[i].style.padding = '36px';
                mathDone[i].style.fontSize = '60px';
                mathDone[i].style.width = '240px';
            }
            for (let i = 0; i < body3.length; i++) {
                body3[i].style.fontSize = '78px';
            }
            for (let i = 0; i < header3.length; i++) {
                header3[i].style.fontSize = '78px';
            }

            for (let i = 0; i < fchpbut1.length; i++) {
                fchpbut1[i].style.fontSize = '52px';
            }
            for (let i = 0; i < choice2Elements.length; i++) {
                choice2Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < option2Elements.length; i++) {
                option2Elements[i].style.fontSize = '52px';
            }

        } else {

            for (let i = 0; i < stepElements.length; i++) {
                stepElements[i].style.height = '110px';
                stepElements[i].style.fontSize = '14px';
                stepElements[i].style.borderWidth = '2px';

            }

            for (let i = 0; i < formulaElements.length; i++) {
                formulaElements[i].style.height = '55px';
                formulaElements[i].style.fontSize = '14px';
                formulaElements[i].style.borderWidth = '2px';

            }

            for (let i = 0; i < where2Elements.length; i++) {
                where2Elements[i].style.fontSize = '14px';
            }
            for (let i = 0; i < apply2Elements.length; i++) {
                apply2Elements[i].style.fontSize = '14px';
            }
            for (let i = 0; i < dragg2Elements.length; i++) {
                dragg2Elements[i].style.fontSize = '14px';
            }
            for (let i = 0; i < next2Elements.length; i++) {
                next2Elements[i].style.fontSize = '14px';
            }
            
            for (let i = 0; i < notnow2Elements.length; i++) {
                notnow2Elements[i].style.fontSize = '14px';
            }
            for (let i = 0; i < xElements.length; i++) {
                xElements[i].style.fontSize = '14px';
            }
            for (let i = 0; i < step0Elements.length; i++) {
                step0Elements[i].style.fontSize = '14px';
            }

            for (let i = 0; i < icon2Elements.length; i++) {
                icon2Elements[i].style.height = '26px';
            }

            for (let i = 0; i < congratulations2Elements.length; i++) {
                congratulations2Elements[i].style.fontSize = '30px';
            }

            for (let i = 0; i < close2Elements.length; i++) {
                close2Elements[i].style.fontSize = '20px';
            }

            for (let i = 0; i < goal2Elements.length; i++) {
                goal2Elements[i].style.fontSize = '26px';
            }

            for (let i = 0; i < check2Elements.length; i++) {
                check2Elements[i].style.height='14px';
                check2Elements[i].style.width ='14px';
            }

            for (let i = 0; i < modalBody2Elements.length; i++) {
                modalBody2Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < k2Elements.length; i++) {
                k2Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < k3Elements.length; i++) {
                k3Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < math2.length; i++) {
                math2[i].style.padding = '10px';
            }
            for (let i = 0; i < mathDone.length; i++) {
                mathDone[i].style.padding = '18px';
                mathDone[i].style.fontSize = '30px';
                mathDone[i].style.width = '120px';
            }
            for (let i = 0; i < body3.length; i++) {
                body3[i].style.fontSize = '26px';
            }
            for (let i = 0; i < header3.length; i++) {
                header3[i].style.fontSize = '26px';
            }
            for (let i = 0; i < fchpbut1.length; i++) {
                fchpbut1[i].style.fontSize = '26px';
            }
            for (let i = 0; i < choice2Elements.length; i++) {
                choice2Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < option2Elements.length; i++) {
                option2Elements[i].style.fontSize = '26px';
            }

        }
        

    }

    closeChoice() {

        var modal = document.getElementById("show-choice");
        modal.style.display = "none";


    }

    showChoice() {

        var modal = document.getElementById("show-choice");
        modal.style.display = "block";


    }
    
    calculateScale(){
        let totalWidth = window.innerWidth;
        let scale=2;
            if(totalWidth<=1700){
                scale=2;
            }else{scale=1;}
      return scale;      
    }

    async copyT(textToCopy) {
        try {
            await navigator.clipboard.writeText(textToCopy);
            console.log('copied to clipboard')
        } catch (error) {
            console.log('failed to copy to clipboard. error=' + error);
        }
    }

    render() {
        if (this.state.l0 === false) {
            return (<Redirect to="/landing"/>);
        } else if (this.state.role === "NO") {
            return <p>Hello! Solver not connected to the website!</p>
        } else
            return (

                <div>

                    <div id="myModal" class="modal">


                        <div class="modal-content">


                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <p class='congra2' > {this.state.stringName.congratulations} </p>
                                <button class="close" onClick={() => this.closeCongratulations()}>OK</button>
                            </div>

                            <div class="modal-footer">
                            </div>

                        </div>

                    </div>


                    <div id="noStepSelected" class="modal">


                        <div class="modal-content">


                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <p> {this.state.stringName.you_have_not_selected_a_step} </p>
                                <button class="close" onClick={() => this.closeNoStepSelected()}>OK</button>
                            </div>

                            <div class="modal-footer">
                            </div>

                        </div>

                    </div>


                    <div id="stageDialog" class="modal">


                        <div class="modal-content">


                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <p> {this.state.stageDialogText} </p>
                                <InlineMath>{this.state.stageDialogKatex}</InlineMath>
                                <p> {this.state.stringName.after_press_next_or_apply} </p>
                                <button class="close" onClick={() => this.closeStageDialog()}>OK</button>
                            </div>

                            <div class="modal-footer">
                            </div>

                        </div>

                    </div>

                    <div id="isAlreadyGeneratedStep" class="modal">


                        <div class="modal-content">


                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <p> {this.state.stringName.step_is_already_generated} </p>
                                <button class="close" onClick={() => this.closeIsAlreadyGeneratedStep()}>OK</button>
                            </div>

                            <div class="modal-footer">
                            </div>

                        </div>

                    </div>


                    <div id="description1" class="modal">


                        <div class="modal-content">


                            <div class="modal-header">

                            </div>

                            <div class="modal-body">
                                <div class="descr0">
                                    <BlockMath>{this.state.description}</BlockMath>
                                </div>
                                <button class="close" onClick={() => {
                                    var modal = document.getElementById("description1");
                                    modal.style.display = "none";
                                }}>OK
                                </button>
                            </div>

                            <div class="modal-footer">
                            </div>

                        </div>

                    </div>

                     
                    <ListFormulasChapters
                        getfChapterContent={this.getfChapterContent.bind(this)}
                        forceUpdate0={this.forceUpdate.bind(this)}
                        chapters={this.state.chapters}
                        stringName={this.state.stringName}
                    />
                     {console.log("choice formula "+this.state.choice.formula)}
                     {console.log("formul katex "+this.state.formulaKatex)}
                     {console.log("choice context katex "+this.state.choice.contextKatex)}
                     {console.log("choice formula "+this.state.insertChoiceAlert)}
                     {console.log("string  name "+this.state.stringName)}

                    <InsertChoice

                        sendLatexChoice={this.sendLatexChoice.bind(this)}
                        sendChoice={this.sendChoice.bind(this)}
                        choiceFormula={this.state.choice.formula}
                        formulaKatex={this.state.formulaKatex}
                        choiceContext={this.state.choice.contextKatex}
                        choicesKatex={this.state.choice.choicesKatex}
                        insertChoiceAlert={this.state.insertChoiceAlert}
                        stringName={this.state.stringName}

                    />    
                          
                 
                    <div className="goal"> {this.state.stringName.you_have_to_get_to}
                        <div className='k3' dangerouslySetInnerHTML={{__html:this.state.stringName.targetKatex}}/>
                        {(this.state.puzzle!==null&this.state.puzzle!==undefined)?<button onClick={()=>{this.copyT(JSON.stringify(this.state.puzzle))}}>copy2</button>:""}
                    </div>

                    {(this.state.role === "GEO_2D" | this.state.role === "GEO_3D") ? (
                        <div id="canvas1">
                            <canvas id="canvas0" width={(500*this.calculateScale())+"px"} height={(250*this.calculateScale())+"px"}></canvas>

                        </div>) : ""}


                    <div className="SolverStepsA" id="SolverSteps0">

                        <ListStepsA steps={this.state.steps}
                                    delete={this.delete.bind(this)}
                                    stringName={this.state.stringName}
                                    selectSt={this.selectSt.bind(this)}
                                    drawSelected={this.drawSelected.bind(this)}
                                    role={this.state.role}/>

                    </div>

                    <div className="goal"> 
                   
                    {this.state.stringName.use_the_following_formulas}
                     <br></br>

                     {(this.state.selectedFChapterName==="")?<div></div>:(<button className='fchpbut' onClick={() => {
                     var modal = document.getElementById("fchapter-id");
                     modal.style.display = "block";
                     }}> <img src="toolbox.png" className='icon2' alt="" /> {this.state.stringName[this.state.selectedFChapterName]}</button>)}
                    
                    </div>
                    <div className="SolverFormulasA" id="SolverFormulas0">

                        <ListFormulasA formulas={this.state.formulas} 
                                       resetFormulas={this.resetFormulas.bind(this)}
                                       stringName={this.state.stringName}
                                       whereToApply={this.whereToApply.bind(this)}                                       
                                       role={this.state.role}/>

                    </div>
                </div>


            );
    }
}



function ListFormulasChapters(props) {

    const stringName = props.stringName;
    const chapters = props.chapters;
    const getfChapterContent_ = props.getfChapterContent;
    const forceUpdate_ = props.forceUpdate0;


    return <div id="fchapter-id" className="modal">
            <div className="modal-content">
             <div className="modal-header"></div>
             <div className="modal-body">
        

                    {chapters.map((chapter_j, index2) =>
                        <div className="schp"
                             onClick={() => {
                                 getfChapterContent_(chapter_j.chapter);
                                 forceUpdate_();
                                 var modal = document.getElementById("fchapter-id");
                                 modal.style.display = "none";
                             }}>
                            { (index2 + 1) + " " + stringName[chapter_j.chapter]}

                        </div>
                    )}


                
            
             
             </div>

            <div className="modal-footer">
            <button className="ok0" onClick={() => {
                    var modal = document.getElementById("fchapter-id");
                    modal.style.display = "none";
                }}>{stringName.close}</button>
            </div>
            </div>
        </div>
    ;
}


function ListChoices(props) {
    const formula_ = props.formula;
    const choices = props.choices;
    const select_ = props.select;
    const stringName = props.stringName;


    const choicesT = choices.map((choice, index) => {
        return <div className="choices">
            <p>

                <InlineMath>{choice.katex}</InlineMath>
                <button className="selectApply"
                        onClick={() => select_(formula_, index)}>{stringName.select_and_apply}</button>


            </p>

        </div>
    })
    if (choices.length > 0)
        return <div>

            {choicesT}


        </div>;
    else return <div> Formula editor</div>
}

function DisplayFormula(props) {
    const formula_ = props.formula;

    return <div>
        <InlineMath>{formula_}</InlineMath>
    </div>;

}


function ListStepsA(props) {

    const stepsA = props.steps;
    const delete_ = props.delete;
    const stringName = props.stringName;
    const selectSt = props.selectSt;
    const drawSelected_ = props.drawSelected;
    const role_ = props.role;

    


    const listStepsA = stepsA.map((value, index) => {
        if (value.canBeDeleted) {
            return <div className="list2" key={value.key} id={"S" + value.key}>
                <div>

                    <span className="step"> {value.step}</span>
                    
                    {(value.checkType === 1) ?
                        <div className="checkb">
                        </div>

                        : (value.checkType === 2) ?
                            <div>

                                <input type="checkbox" id={"ck" + value.key} checked={false} className="checkb"
                                       onClick={() => selectSt(value.key)}/>
                            </div>


                            : (value.checkType === 3) ?

                                <div>

                                    <input type="checkbox" id={"ck" + value.key} checked={true} className="checkb"
                                           onClick={() => selectSt(value.key)}/>
                                </div>
                                : <div className="checkb">
                                </div>
                    }
                    <br></br> 
                    
                    <div>
                        <div className='k0'> {(role_==="EQL"&index!==0)?"=":""}</div>
                       
                    <div className='k2'
                     dangerouslySetInnerHTML={{__html: value.katex}}>
                    </div> 
                    </div>

                    {(value.checkType === 1) ?
                        <button className="x" onClick={() => delete_(value.key)}>  <img src="eraser.png" height="26" className="icon2" alt="" /> {stringName.delete} </button> :
                        <div></div>}
                    {(role_ === "GEO_2D" | role_ === "GEO_3D") ? <button className="where2"
                                                                         onClick={() => drawSelected_(value.key)}>{stringName.color_it}  </button> :
                        <div></div>}
              


                </div>

            </div>
        } else {
            return <div className="list2" key={value.key} id={"S" + value.key}>
                <div>

                    <span className="step"> {value.step}</span>
                
                    {(value.checkType === 1) ?
                        <div className="checkb">
                        </div>

                        : (value.checkType === 2) ?
                            <div>

                                <input type="checkbox" id={"ck" + value.key} checked={false} className="checkb"
                                       onClick={() => selectSt(value.key)}/>
                            </div>


                            : (value.checkType === 3) ?

                                <div>

                                    <input type="checkbox" id={"ck" + value.key} checked={true} className="checkb"
                                           onClick={() => selectSt(value.key)}/>
                                </div>
                                : <div className="checkb">
                                </div>
                    }
                    <br></br>
                    <div>
                    <div className='k0'> {(role_==="EQL"&index!==0)?"=":""}</div>
                    <div className='k2' 
                    dangerouslySetInnerHTML={{__html: value.katex}} >
                    </div>
                    </div>

                    {(role_ === "GEO_2D" | role_ === "GEO_3D") ? <button className="where2"
                                                                         onClick={() => drawSelected_(value.key)}>{stringName.color_it}  </button> :
                        <div></div>}
                                      

                </div>

            </div>
        }

    })
    return <div>

        {listStepsA}
        


    </div>;
}


function ListStepsForTask(props) {
    let steps = props.steps;
    if (steps === undefined) {
        steps = [];
    }

    const listSteps = steps.map((value, index) => {

        return <div className="list2t" key={value.key} id={"S" + value.key}>
            <p>
                <span className="step2"> {value.step}</span>

                <br></br>
                <div className='k1' dangerouslySetInnerHTML={{__html: value.katex}}/>


            </p>

        </div>

    })
    return <div className="ExerciseStepsOfTask">

        {listSteps}


    </div>;
}

function checkIfResetFormulas(formulas) {
    var reset = false;
    formulas.map((value) => {
        if (value.type !== 1) {
            reset = true;
        }
    })
    return reset;
}

function ListFormulasA(props) {
    const formulasA = props.formulas;
    const where_ = props.whereToApply;
    const stringName = props.stringName;
    const resetFormulas_ = props.resetFormulas;
    const role_ = props.role;

    const listFormulasA = formulasA.map(formulaA => {
        if (formulaA.type === 1) {
            return <div className="list3" key={formulaA.key} id={"F" + formulaA.key}>
                <div>
                    <span className="descr"> {formulaA.descr}</span>

                    <br></br>
                    <InlineMath>{formulaA.katex}</InlineMath>
                    <br></br> 
                    <button className="where2" onClick={() => where_(formulaA.key)}>
                        <img src="where2.png" height="26" className="icon2" alt="" /> {stringName.can_be_applied} </button>


                </div>

            </div>
        } else if (formulaA.type === 2) {
            return <div className="list3" key={formulaA.key} id={"F" + formulaA.key}>
                <div>
                    <span className="descr"> {formulaA.descr}</span>

                    <br></br>
                    <InlineMath>{formulaA.katex}</InlineMath>

                    <br></br>
                    <p className="dragg2"> {stringName.select_a_rectangle} </p> 

                    


                </div>

            </div>

        } else if (formulaA.type === 7) {
            return <div className="list3" key={formulaA.key} id={"F" + formulaA.key}>
                <div>
                    <span className="descr"> {formulaA.descr}</span>
                    <br></br>
                    <span className="descr2"> {"[" + stringName.please_select_a_statement + "]"}</span>
                   

                </div>

            </div>

        } else if (formulaA.type === 8) {
            let stageX = (formulaA.currentStage === undefined) ? 0 : formulaA.currentStage;
            let katexX = (formulaA.stages[stageX].f === undefined) ? "" : formulaA.stages[stageX].f;
            let stagesLength = (formulaA.stages === undefined) ? -1 : formulaA.stages.length;
            let stageType = "S";
            if (stagesLength > 0) {
                stageType = formulaA.stages[stageX].t;
            }
            let isLastStage = true;
            if (stageX + 1 !== stagesLength) {
                isLastStage = false;
            }
            return <div className="list3" key={formulaA.key} id={"F" + formulaA.key}>
                <div>
                    <span className="descr"> {stringName.select_something_of_shape}</span>

                    <br></br>
                    <InlineMath>{katexX}</InlineMath>

                    <br></br> 
                    
                </div>

            </div>


        } else if (formulaA.type === 4) {

            return <div className="list3" key={formulaA.key} id={"F" + formulaA.key}>
                <div>
                    <span className="descr"> {formulaA.descr}</span>

                    <br></br>
                    <InlineMath>{formulaA.katex}</InlineMath>
                    <br></br>   
                    <p className="notnow2">
                        <img src="notnow.png" height="26" className="icon2" alt="" /> {stringName.not_applicable_now} </p>


                </div>

            </div>

        } else if (formulaA.type === 3) {
            return <div className="list3" key={formulaA.key} id={"F" + formulaA.key}>
                <div>
                    <span className="descr"> {formulaA.descr}</span>

                    <br></br>
                    <InlineMath>{formulaA.katex}</InlineMath>

                    <br></br>
                    <p className="dragg2"> {stringName.select_a_rectangle} </p> 



                </div>

            </div>

        } else if (formulaA.type === 6) {

             return <div className="list3" key={formulaA.key} id={"F" + formulaA.key}>
                        <div>
                            <span className="descr"> {formulaA.descr}</span>

                            <br></br>
                            <InlineMath>{formulaA.katex}</InlineMath>
                            <br></br>   
                            <p className="dragg2"> {stringName.drag_and_drop_symbols} </p> 
                            
                          

                        </div>

                    </div>

        }
    })
    return <div>

        {listFormulasA}

        {(role_ === "GEO_2D" | role_ === "GEO_3D" | role_ === "SYS") ? (checkIfResetFormulas(formulasA) ?
                <button className="send-to-teacher"
                        onClick={() => resetFormulas_()}>{stringName.reset_formulas}  </button> : <div></div>) :
            <div></div>}


    </div>;
}


class Landing0 extends React.Component {
    constructor(props) {
        super(props);
        this.arrangeLanding();       
        
        window.onresize=this.arrangeLanding;
    }
     
    arrangeLanding() {

        let totalWidth = window.innerWidth;
       
      

        var a1Elements = document.getElementsByClassName("try1");
        var a2Elements = document.getElementsByClassName("Land");
        var a3Elements = document.getElementsByClassName("text_la");

      
       
        
        if (totalWidth <= 1700) {

           

            for (let i = 0; i < a1Elements.length; i++) {
                a1Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a2Elements.length; i++) {
                a2Elements[i].style.minHeight = '1800px';
            }
            for (let i = 0; i < a3Elements.length; i++) {
                a3Elements[i].style.fontSize = '52px';
            }
                    


        } else {

          

            for (let i = 0; i < a1Elements.length; i++) {
                a1Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a2Elements.length; i++) {
                a2Elements[i].style.minHeight = '900px';
            }
            for (let i = 0; i < a3Elements.length; i++) {
                a3Elements[i].style.fontSize = '26px';
            }
           
            


        }
        

    }

     

    guest() {

        const rr0 = Math.floor(Math.random() * 100000000001);
        localStorage.clear();
        localStorage.setItem("rr1", (rr0 + ""));
        const data = {message: "LGS0", rr: (rr0 + "")};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                this.setState(json);

                if (this.state.l00 === true) {
                    window.location = '/';
                }

            })
            .catch(err => console.log(err));
        //alert("You are welcome");

    }


    render() {

        return (

            <div className='Land'>

            <div className="divlanding">
                <div className='sign1'>
                    <img src="favicon.png" height="200px" alt="" />
                </div>      
                <p className="text_la"> Mmex stands for Math Explorer </p>
                <p className="text_la"> For now here we have interactive math tests only on: equations, inequalities,powers and basic operations in N and Q numbers </p> 

              
                <button className="try1" onClick={() => this.guest()}> Just try the Math Explorer without an account!</button>
                <br></br>
                <button className="try1" onClick={() =>{window.location="/signup"}}> Register as a teacher for free</button>
                <br></br>
                <button className="try1" onClick={() =>{window.location="/login"}}> Login </button>
                <br></br>
               
            </div>

            </div>


        );
    }
}

class Login00 extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            u1: '',
            p1: '',
        };
        
        window.onresize=this.arrangeLogin;
    }
     
    arrangeLogin() {

        let totalWidth = window.innerWidth;
     
      

    
        var a2Elements = document.getElementsByClassName("user_pass");
        var a3Elements = document.getElementsByClassName("user_pass_text");

      
       
        
        if (totalWidth <= 1700) {

                   
            for (let i = 0; i < a2Elements.length; i++) {
                a2Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a2Elements.length; i++) {
                a3Elements[i].style.fontSize = '52px';
            }
                    


        } else {

                     
            for (let i = 0; i < a2Elements.length; i++) {
                a2Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a2Elements.length; i++) {
                a3Elements[i].style.fontSize = '26px';
            }
           
            


        }
        

    }

    mySubmitHandler = (event) => {
        event.preventDefault();
        const rr0 = Math.floor(Math.random() * 100000000001);
        localStorage.clear();
        localStorage.setItem("rr1", (rr0 + ""));
        const data = {message: "L0", u1: this.state.u1, p1: this.state.p1, rr: (rr0 + "")};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);
                this.setState(json);

                if (this.state.l00 === true) {
                    window.location = '/';
                }else{alert("Username or password is incorrect!");}

            })
            .catch(err => console.log(err));
        //alert("You are welcome");

    }
    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }  
   
    render() {

        return (

            <div className="divlogin">

                <p className="user_pass_text"> Welcome to mmex.app </p>
                
                <div className='sign1'>
                    <img src="favicon.png" height="200px" alt="" />
                </div>                
                
                               
                <form className="login" onSubmit={this.mySubmitHandler}>

                    <p className="user_pass_text"> Enter your email:</p>
                    <input className="user_pass"
                           type='text'
                           name='u1'
                           onChange={this.myChangeHandler}
                    />
                    <p className="user_pass_text">Enter your password:</p>
                    <input className="user_pass"
                           type='password'
                           name='p1'
                           onChange={this.myChangeHandler}
                    />


                    <br/>
                    <br/>
                    <input className="user_pass" type='submit'/>
                </form>
            </div>


        );
    }
}

class Test00 extends React.Component {
    constructor(props) {
        super(props);
        this.arrangeTest();
        this.state = {
           code: 'test0',
           teacher: 't0',
           exercises:"A,B,C,D",
           date:"2024",
           lang:"en",
           listExercises:"",
           usedChapters:[],
           stringName:{}           
        };
        
        window.onresize=this.arrangeTest;

        const queryString = window.location.search;
        console.log(queryString);
        const urlParams = new URLSearchParams(queryString);
        
        const t0 = urlParams.get('t')
        console.log(t0);
        
        const data = {message: "GET_TEST",t:t0};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                     this.arrangeTest();  
                     if(localStorage.getItem("rr1")===null){
                        var modal1=document.getElementById("cookies0");                        
                            modal1.style.display="block";                    }
                });

            })
            .catch(err => console.log(err));
    }
     
    openTaskDirect(teacher1, task1) {
       
        const data = {message: "OPEN_TASK_DIRECT", teacher: teacher1, task: task1, rr: obtainValue()};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);
               
                window.location="/";

            })
            .catch(err => console.log(err));
    }

    guestForTask(lang1) {

        const rr0 = Math.floor(Math.random() * 100000000001);
        localStorage.clear();
        localStorage.setItem("rr1", (rr0 + ""));
        const data = {message: "TLGS0",lang:lang1,rr: (rr0 + "")};
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                console.log(json);
               

            })
            .catch(err => console.log(err));
        

    }

    arrangeTest() {

        let totalWidth = window.innerWidth;
       
      

        var a1Elements = document.getElementsByClassName("text_t2");
        var a2Elements = document.getElementsByClassName("send-to");
        var a3Elements = document.getElementsByClassName("text_t");

      
       
        
        if (totalWidth <= 1700) {

           

            for (let i = 0; i < a1Elements.length; i++) {
                a1Elements[i].style.fontSize = '60px';
            }
            for (let i = 0; i < a2Elements.length; i++) {
                a2Elements[i].style.fontSize = '52px';
            }
            for (let i = 0; i < a3Elements.length; i++) {
                a3Elements[i].style.fontSize = '52px';
            }
                    


        } else {

          

            for (let i = 0; i < a1Elements.length; i++) {
                a1Elements[i].style.fontSize = '30px';
            }
            for (let i = 0; i < a2Elements.length; i++) {
                a2Elements[i].style.fontSize = '26px';
            }
            for (let i = 0; i < a3Elements.length; i++) {
                a3Elements[i].style.fontSize = '26px';
            }
           
            


        }
        

    }

      

   
   
    render() {

        return (

            <div className="divtest">

                <p className="text_t"> {this.state.stringName.interactive_math_test} </p>
                
                <div className='sign1'>
                    <img src="favicon.png" height="100px" alt="" />
                </div> 

                 <div id="cookies0" class="modal">


                  <div class="modal-content">


                  <div class="modal-header">

                  </div>

                  <div class="modal-body">
                  <p className="text_t"> {"Accept cookies?"} </p>
                  <button class="close" onClick={() => {
                    var modal1=document.getElementById("cookies0");            
                        modal1.style.display="none";
                        this.guestForTask(this.state.lang);
                    
                  }}>OK</button>
                  </div>

                  <div class="modal-footer">
                 </div>

                 </div>

                 </div>                             
                <div>
                   
                    <p className="text_t">{this.state.stringName.mission_code}{this.state.code}</p>
                    <p className="text_t">{this.state.stringName.exercises3}{this.state.listExercises}</p>
                    <div>
                       <p className="text_t2" >{this.state.stringName.used_chapters}</p> <br></br>
                       {this.state.usedChapters.map( ch_i =>  <p className="text_t2">{this.state.stringName[ch_i]}<br></br></p>)} 
                    </div>
                    <p className="text_t">{this.state.stringName.created_at2}{this.state.date}</p>
                </div>
                <div>
                <button className="send-to" onClick={() => {
                                            this.openTaskDirect(this.state.teacher,this.state.code);                                         
                                        }}>{this.state.stringName.open_mission}</button>
                </div>
               
               
                 
                 </div>


        );
    }
}




class SignUp0 extends React.Component {
    constructor(props) {
        super(props);
        this.arrangeSignup();
        this.state = {
            status: "NO",
            lang: "en"
        };

        window.onresize=this.arrangeSignup;

        this.getForm("en");
    }

    getForm(lang1) {

       

        const data = {
            message: "GS0",
            lang: lang1
        };
        fetch(WebService1, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);

                this.setState(json,()=>{
                    if (this.state.status === "OK") {
                        document.getElementById("u1").value = "";
                        document.getElementById("p1").value = "";
                        document.getElementById("p2").value = "";
                        document.getElementById("name").value = "";
                        document.getElementById("school").value = "";
                        document.getElementById("role0").value = "0";
            
                        this.setState({
                            u1: "",
                            u1Alert: "",
                            p1: "",
                            p2: "",
                            p2Alert: "",
                            name: "",
                            nameAlert: "",
                            school: "",
                            schoolAlert: "",
                            role: "0",
                            roleAlert: ""
                        });
                    }
                });

            })
            .catch(err => console.log(err));

    }

    mySubmitHandler = (event) => {
        event.preventDefault();


        let isAProblem = false;
        if (this.state.u1 === '') {
            this.setState({"u1Alert": this.state.stringName.email_id_is_empty});
            isAProblem = true;
        } else {
            this.setState({"u1Alert": ""});
        }

        if ((this.state.p1 === "") | (this.state.p2 === "")) {
            this.setState({"p2Alert": this.state.stringName.password_is_empty});
            isAProblem = true;
        } else if (this.state.p2 !== this.state.p1) {
            this.setState({"p2Alert": this.state.stringName.passwords_must_match});
            isAProblem = true;
        } else if (this.state.p1.length < 8) {
            this.setState({"u1Alert": this.state.stringName.password_must_have_at_least_8_characters});
            isAProblem = true;
        } else {
            this.setState({"p2Alert": ""});
        }

        if (this.state.name === '') {
            this.setState({"nameAlert": this.state.stringName.your_name_must_filled});
            isAProblem = true;
        } else {
            this.setState({"nameAlert": ""});
        }

        if (this.state.school === '') {
            this.setState({"schoolAlert": this.state.stringName.school_name_must_filled});
            isAProblem = true;
        } else {
            this.setState({"schoolAlert": ""});
        }   

        if (this.state.role === "0") {
            this.setState({"roleAlert": this.state.stringName.choose_student_or_teacher});
            isAProblem = true;
        } else {
            this.setState({"roleAlert": ""});
        }
        if (!isAProblem) {
            const data = {
                message: "S0",
                status: "OK",
                lang: this.state.lang,
                stringName: this.state.stringName,
                u1: this.state.u1,
                u1Alert: this.state.u1Alert,
                p1: this.state.p1,
                p2: this.state.p2,
                p2Alert: this.state.p2Alert,
                name: this.state.name,
                nameAlert: this.nameAlert,
                school: this.state.school,
                schoolAlert: this.state.schoolAlert,
                role: this.state.role,
                roleAlert: this.state.roleAlert
            };
            fetch(WebService1, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(response => response.json())
                .then(json => {
                    console.log(json);
                    this.setState(json);

                    if (this.state.s00 === true) {
                        alert("Sign up succesfull!");
                        window.location = '/login';
                    }

                })
                .catch(err => console.log(err));
        }

    }
    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
    }

    arrangeSignup() {

        let totalWidth = window.innerWidth;
       
      

        var a1Elements = document.getElementsByClassName("signupAlert");
        var a2Elements = document.getElementsByClassName("user_pass_s");
        var a3Elements = document.getElementsByClassName("signup_text");

      
       
        
        if (totalWidth <= 1700) {

           

            for (let i = 0; i < a1Elements.length; i++) {
                a1Elements[i].style.fontSize = '40px';
            }
            for (let i = 0; i < a2Elements.length; i++) {
                a2Elements[i].style.fontSize = '40px';
            }
            for (let i = 0; i < a3Elements.length; i++) {
                a3Elements[i].style.fontSize = '40px';
            }
                    


        } else {

          

            for (let i = 0; i < a1Elements.length; i++) {
                a1Elements[i].style.fontSize = '20px';
            }
            for (let i = 0; i < a2Elements.length; i++) {
                a2Elements[i].style.fontSize = '20px';
            }
            for (let i = 0; i < a3Elements.length; i++) {
                a3Elements[i].style.fontSize = '20px';
            }
           
            


        }
        

    }

    render() {
        if (this.state.status === "OK") {
            return (
                <form className="signup" onSubmit={this.mySubmitHandler}>


                    <p className="signup_text"> Select your language: </p>
                    <form>
                        <select
                            onChange={(event) => {
                                this.getForm(event.target.value);
                            }}
                            value={this.state.lang}
                            className="user_pass_s"
                        >
                            <option value="en">english</option>
                            <option value="ro"> română</option>


                        </select>
                    </form>


                    <p className="signup_text"> {this.state.stringName.enter_your_email}</p>

                    <input id="u1" className="user_pass_s"
                           type='text'
                           name='u1'
                           onChange={this.myChangeHandler}
                    />
                    <p className="signupAlert"> {this.state.u1Alert}</p>

                    <p className="signup_text">{this.state.stringName.enter_your_password}</p>

                    <input id="p1" className="user_pass_s"
                           type='password'
                           name='p1'
                           onChange={this.myChangeHandler}
                    />

                    <p className="signup_text">{this.state.stringName.retype_your_password}</p>
                    <input id="p2" className="user_pass_s"
                           type='password'
                           name='p2'
                           onChange={this.myChangeHandler}
                    />
                    <p className="signupAlert"> {this.state.p2Alert}</p>

                    <p className="signup_text"> {this.state.stringName.your_family_name_and_your_first_name}</p>
                    <input id="name" className="user_pass_s"
                           type='text'
                           name='name'
                           onChange={this.myChangeHandler}
                    />

                    <p className="signupAlert"> {this.state.nameAlert}</p>

                    <p className="signup_text"> {this.state.stringName.your_school_name}</p>
                    <input id="school" className="user_pass_s"
                           type='text'
                           name='school'
                           onChange={this.myChangeHandler}
                    />
                    <p className="signupAlert"> {this.state.schoolAlert}</p>                             

                    <p className="signup_text"> {this.state.stringName.you_are_student_or_teacher}</p>
                    <form>
                        <select id="role0"
                                onChange={(event) => {
                                    this.setState({"role": event.target.value});
                                }}
                                value={this.state.role}
                                className="user_pass_s"
                        >
                            <option value="0">{this.state.stringName.nothing_choosed}</option>
                            <option value="2"> {this.state.stringName.teacher_} </option>

                        </select>
                    </form>
                    <p className="signupAlert"> {this.state.roleAlert}</p>

                    <br/>
                    <br/>
                    <input className="user_pass_s" type='submit' value={this.state.stringName.submit}/>
                </form>

            );
        } else if (this.state.status === "NO") {
            return (<p>Wait!</p>)
        }
    }
}